<template>
  <div class="anchor-sorter">
    <span class="anchor-sorter__label">
      <b>{{ $tc("labels.gateway", 1) }}</b>
      <span class="sort-icon" @click.stop="sortAnchors('label')">
        <i
          :class="[
            'el-icon-sort-down',
            anchorOrder.labelAsc === false && anchors.length > 1
              ? 'template-bold'
              : ''
          ]"
        ></i>
        <i
          :class="[
            'el-icon-sort-up',
            anchorOrder.labelAsc === true && anchors.length > 1
              ? 'template-bold'
              : ''
          ]"
        ></i>
      </span>
    </span>
    <span class="anchor-sorter__number-tags">
      <b>{{ $t("labels.quantity") }}</b>
      <span class="sort-icon" @click.stop="sortAnchors('tags')">
        <i
          :class="[
            'el-icon-sort-down',
            anchorOrder.tagsAsc === false && anchors.length > 1
              ? 'template-bold'
              : ''
          ]"
        ></i>
        <i
          :class="[
            'el-icon-sort-up',
            anchorOrder.tagsAsc === true && anchors.length > 1
              ? 'template-bold'
              : ''
          ]"
        ></i>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: "AnchorSorter",
  props: {
    anchors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      anchorOrder: {
        labelAsc: null,
        tagsAsc: null
      }
    };
  },
  methods: {
    sortAnchors(criteria) {
      switch (criteria) {
        case "label":
          this.anchorOrder.labelAsc = !this.anchorOrder.labelAsc;
          this.anchorOrder.tagsAsc = null;
          break;
        case "tags":
          this.anchorOrder.tagsAsc = !this.anchorOrder.tagsAsc;
          this.anchorOrder.labelAsc = null;
          break;
      }
      this.$emit("update-anchors", criteria, this.anchorOrder);
    }
  }
};
</script>

<style lang="scss">
.anchor-sorter {
  display: flex;
  gap: 20px;

  &__label,
  &__number-tags {
    display: flex;

    .sort-icon {
      position: relative;
      cursor: pointer;

      .template-bold {
        font-weight: bold;
      }

      .el-icon-sort-up {
        position: absolute;
        left: 8px;
      }
    }
  }
}
</style>
