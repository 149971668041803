var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"routes-anchor-card"},[_c('el-card',{class:_vm.anchorsLayout === 'lines' ? 'lines-layout' : 'grid-layout'},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"anchor-label"},[(_vm.showShiftConflict)?_c('span',{staticClass:"conflict-marker"},[_vm._v("* ")]):_vm._e(),_vm._v(_vm._s(_vm.anchor?.label))])])]),_c('div',{class:[
        'anchor-routes',
        _vm.anchorsLayout === 'lines'
          ? 'routes-lines-layout'
          : 'routes-grid-layout'
      ]},[_c('el-select',{attrs:{"placeholder":_vm.$tc('placeholder.selectRoute', 1),"multiple":"","filterable":""},on:{"change":_vm.handleRouteOverlap,"remove-tag":_vm.onRemoveRoute},model:{value:(_vm.selectedRouteIds),callback:function ($$v) {_vm.selectedRouteIds=$$v},expression:"selectedRouteIds"}},_vm._l((_vm.routes),function(route){return _c('el-option',{key:route.id,attrs:{"label":`${route.label} (${_vm.formatTime(
              route.constraintShiftStart
            )} - ${_vm.formatTime(route.constraintShiftEnd)})`,"value":route.id}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(" "+_vm._s(route.label))]),_c('span',{staticStyle:{"float":"left","padding":"0 5px"}},[_c('i',{class:route.constraintTag
                  ? 'el-icon-location-outline'
                  : route.constraintShiftStart != 0 ||
                    route.constraintShiftEnd != 86399
                  ? 'el-icon-time'
                  : ''})]),(
              route.constraintShiftStart != 0 ||
                route.constraintShiftEnd != 86399
            )?_c('span',{staticStyle:{"float":"right","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.formatTime(route.constraintShiftStart))+" - "+_vm._s(_vm.formatTime(route.constraintShiftEnd))+" ")]):(route.constraintTag)?_c('span',{staticStyle:{"float":"right","font-size":"12px"}},[_vm._v(" "+_vm._s(route.constraintTag)+" ")]):_vm._e()])}),1)],1),_c('div',{staticClass:"submit-button"},[_c('el-tooltip',{attrs:{"disabled":!_vm.showShiftConflict,"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t("messages.shiftConflict"))+": "),_c('br'),_c('br'),_vm._l((_vm.routesInConflict),function(route){return _c('div',{key:route.routeALabel + route.routeBLabel},[_vm._v(" "+_vm._s(route.routeALabel)+" "+_vm._s(_vm.$t("labels.with"))+" "+_vm._s(route.routeBLabel)+" ")])})],2)]},proxy:true}])},[_c('el-button',{attrs:{"type":"primary","size":"mini","disabled":!_vm.anchor.id || _vm.showShiftConflict},on:{"click":_vm.onSubmitRoutes}},[_vm._v(_vm._s(_vm.$t("button.submit")))])],1)],1),(_vm.showShiftConflict)?_c('div',{staticClass:"shift-conflict"},[_vm._v(" * "+_vm._s(_vm.$t("messages.shiftConflict"))+" ")]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }