
import Vue from "vue";
import moment from "moment-timezone";
import { mapGetters, mapActions, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import HeaderMenu from "@/components/HeaderMenu.vue";

export default Vue.extend({
  name: "app",

  components: {
    HeaderMenu
  },

  data() {
    return {
      isLoading: true,
      predictionTableInterval: 0,
      currentTime: ""
    };
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),

    ...mapState("auth", {
      user: "user"
    }),

    ...mapState("clients", {
      selectedAnchor: "selectedAnchor",
      anchors: "anchors",
      clientData: "data"
    })
  },

  watch: {
    "user.data.isIframe"(newValue) {
      if (newValue && this.user.data.redirect) {
        if (this.user.data.redirect === "home") {
          this.$router.push({ name: "dashboard" });
        } else if (this.user.data.redirect === "settings") {
          this.$router.push({
            name: "user-settings",
            params: { tab: "beacons-location" }
          });
        }
      }
    },
    $route(to) {
      document.title = to.meta.title || "Azitek";

      if (!to.meta.requiresAuth) {
        this.isLoading = false;
      } else {
        if (this.isAuthenticated) {
          if (this.anchors.isEmpty) {
            this.isLoading = false;
          } else {
            this.isLoading = !this.selectedAnchor;
          }
        }
      }
    },

    isAuthenticated: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          this.getClient(this.user.data.clientId);
          await this.getResources();
          this.getAnchors(this.user.data.clientId);
          this.getUsageInfo(this.user.data.clientId);
        }
      }
    },

    selectedAnchor: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue === null) {
          this.isLoading = false;
        }
      }
    },

    "$i18n.locale"(newValue) {
      moment.locale(newValue);
      moment.updateLocale(newValue, {
        months: moment
          .months()
          .map(month => month.charAt(0).toUpperCase() + month.slice(1)), // Capitalize first letter
        monthsShort: moment
          .monthsShort()
          .map(month => month.charAt(0).toUpperCase() + month.slice(1)), // Capitalize first letter
        longDateFormat: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "MM/DD/YYYY",
          LL: "MMMM D, YYYY",
          LLL: "MMMM D, YYYY HH:mm",
          LLLL: "dddd, MMMM D, YYYY HH:mm"
        }
      });
    }
  },

  methods: {
    ...mapActions("clients", {
      getClient: actionTypes.CLIENTS_GET_CLIENT,
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES,
      getUsageInfo: actionTypes.USER_GET_USAGE_INFO
    }),
    ...mapActions("dashboard", {
      setCurrentTime: actionTypes.SET_CURRENT_TIME
    }),

    updateCurrentTime() {
      if (this.clientData) {
        this.currentTime = moment
          .tz(this.clientData.timezone)
          .format("DD/MM/YYYY, HH:mm:ss");
        //+ " (" + this.clientData.timezone + ")"
        this.setCurrentTime(moment.tz(this.clientData.timezone).unix());
      } else this.currentTime = "N/A";
    }
  },

  created() {
    if (!this.$route.meta?.requiresAuth) {
      this.isLoading = false;
    }

    this.predictionTableInterval = setInterval(this.updateCurrentTime, 1000);
    console.log("Current version: ", process.env.VUE_APP_GIT_HASH);
  }
});
