import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import locale from "element-ui//lib/locale/lang/en";
import Fullscreen from "vue-fullscreen";
import moment from "moment";
import VueI18n from "vue-i18n";
import en from "./locales/en.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import sk from "./locales/sk.json";

require("leaflet");
require("leaflet-path-drag");

import "./assets/styles/index.scss";
import "leaflet/dist/leaflet.css";

Vue.use(ElementUI, { locale });

Vue.use(Fullscreen);

Vue.use(VueI18n);

Vue.config.productionTip = false;

const messages = {
  en,
  es,
  pt,
  sk
};

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages
});

moment.locale(i18n.locale);
moment.updateLocale(i18n.locale, {
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "MM/DD/YYYY",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY HH:mm",
    LLLL: "dddd, MMMM D, YYYY HH:mm"
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
