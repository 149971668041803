<template>
  <div class="anchor-overview" :style="`background-color: ${backgroundColor}`">
    <div class="anchor-overview__main">
      <div class="overview-left-content">
        <div class="details-buttons">
          <WifiIcon
            :signal="anchor.rssi !== null ? anchor.rssi : 0"
            :noConnection="isAnchorOffline"
          />
          <el-tooltip
            :content="$t('tooltip.clickEye')"
            placement="left"
            :hide-after="2000"
          >
            <el-button
              :disabled="isAnchorOffline"
              :style="
                `background-color: ${
                  selectedAnchor && !isAnchorOffline ? anchor.color : '#ffffff'
                }`
              "
              @click="selectAnchor()"
            >
              <img
                v-if="selectedAnchor && !isAnchorOffline"
                src="@/assets/eye.svg"
                :alt="$t('alt.eye')"
              />
              <img v-else src="@/assets/eye-off.svg" :alt="$t('alt.eyeOff')" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.vehicleAnalytics')"
            placement="left"
            :hide-after="2000"
          >
            <el-button @click="redirectToAnalytics">
              <img src="@/assets/bar-chart.svg" :alt="$t('alt.barChart')" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            :content="$t('tooltip.vehicleMetrics')"
            placement="left"
            :hide-after="2000"
          >
            <el-button @click="redirectToDashboard">
              <i class="el-icon-location-outline"></i>
            </el-button>
          </el-tooltip>
        </div>
        <div class="anchor-content">
          <div class="anchor-content__head">
            <span class="head-details">
              <el-tooltip
                placement="top-start"
                :content="anchor.label"
                :disabled="!isTruncated(anchor.anchorId)"
                :hide-after="2000"
              >
                <span class="info-label" :ref="`label-${anchor.anchorId}`">{{
                  anchor.label
                }}</span>
              </el-tooltip>
            </span>
          </div>
          <div
            class="anchor-content__last-position"
            v-if="anchor.lastPositionMetadata?.tagPositionId"
          >
            <span class="last-position-status"
              >{{
                anchor.lastPositionMetadata.state === "LEFT"
                  ? $t("statusMessages.left")
                  : anchor.lastPositionMetadata.state === "ENTERED"
                  ? $t("labels.at")
                  : $t("labels.lastPosition")
              }}:
            </span>
            <div class="last-position-value">
              <el-tooltip
                placement="top-start"
                :content="
                  getLastAnchorPositionLabel(
                    anchor.lastPositionMetadata.tagPositionId
                  )
                "
                :disabled="
                  !isTruncated(anchor.lastPositionMetadata.tagPositionId)
                "
                :hide-after="2000"
              >
                <span
                  class="position-label"
                  :ref="`label-${anchor.lastPositionMetadata.tagPositionId}`"
                  >{{
                    getLastAnchorPositionLabel(
                      anchor.lastPositionMetadata.tagPositionId
                    )
                  }}
                </span>
              </el-tooltip>
              <span> {{ lastPositionTime }}</span>
            </div>
          </div>
          <div class="last-position-value--empty" v-else>
            <span>{{ $t("messages.noInfo") }}</span>
          </div>
        </div>
      </div>
      <div
        class="metrics-details"
        v-if="anchor.usageInfo && anchor.usageInfo.routesInfo.length"
      >
        <span class="metrics-label">{{ $t("labels.today") }}</span>
        <div class="metrics-data">
          <span
            :class="['metrics-value', { valid: percentageValidToday >= 90 }]"
          >
            <i v-if="percentageValidToday >= 90" class="el-icon-top"></i>
            {{ anchorTotalMetrics.validToday }}
            {{ $t("labels.valid").toLowerCase() }} ({{ percentageValidToday }}%)
          </span>
          <span
            :class="['metrics-value', { errors: percentageErrorsToday >= 10 }]"
          >
            <i v-if="percentageErrorsToday >= 10" class="el-icon-bottom"></i>
            {{ anchorTotalMetrics.errorsToday }}
            {{ $t("labels.errors").toLowerCase() }} ({{
              percentageErrorsToday
            }}%)
          </span>
          <span class="metrics-value">
            {{ anchorTotalMetrics.avgDurationToday }}
            {{ $t("labels.avgDuration").toLowerCase() }}
          </span>
        </div>
      </div>
      <div
        :class="[
          'recent-routes',
          anchor.usageInfo?.routesInfo?.length ? 'small-width' : 'large-width'
        ]"
      >
        <div
          class="recent-routes__data"
          v-if="anchor.activeRoute || anchor.recentRoutes?.length"
        >
          <RecentRoutesTimeline :anchor="anchor" :currentTime="currentTime" />
        </div>
        <div
          class="recent-routes__no-data"
          v-else-if="!anchor.usageInfo || anchor.usageInfo.routesInfo.length"
        >
          <span>{{ $t("labels.noData") }}</span>
        </div>
        <div
          class="recent-routes__empty"
          v-else-if="!anchor.usageInfo.routesInfo.length"
        >
          <span class="empty-title">{{
            $t("messages.noRoutesAssociated")
          }}</span>
          <span class="empty-subtitle" @click="redirectToRoutesSettings"
            >({{ $t("messages.noRoutesSubtitle") }}
            <i class="el-icon-right"></i>)</span
          >
        </div>
      </div>
      <div class="details-toggle">
        <span v-if="!isAnchorInfoVisible" @click="toggleAnchorInfo">{{
          $t("actions.more")
        }}</span>
        <span v-else @click="toggleAnchorInfo">{{ $t("actions.less") }}</span>
      </div>
    </div>

    <div class="anchor-overview__expandable-details" v-if="isAnchorInfoVisible">
      <div
        class="expandable-details-info"
        v-if="anchor.usageInfo.routesInfo.length"
      >
        <span class="expandable-details-info__title">{{
          $t("labels.associatedRoutes")
        }}</span>
        <div class="expandable-details-info__metrics">
          <div v-if="activeRouteUsageInfo">
            <RouteMetricsCard
              :usageInfo="activeRouteUsageInfo"
              :isActiveRoute="true"
              :showLastTwoWeeks="false"
              shadow="never"
            />
          </div>
          <div
            v-for="routesInfo in allRoutesUsageInfo"
            :key="routesInfo.routeId"
          >
            <RouteMetricsCard
              :usageInfo="routesInfo"
              :isActiveRoute="false"
              :showLastTwoWeeks="false"
              shadow="never"
            />
          </div>
        </div>
      </div>
      <div class="expandable-details-info">
        <span class="expandable-details-info__title">{{
          $t("labels.lastFivePositions")
        }}</span>
        <LastPositionsTable
          :lastFivePositions="anchor.lastFivePositions"
          width="450px"
          @show-tag-label-on-map="onShowTagLabelOnMap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { formatTimeUnit } from "@/utils/utils";
import { mapState, mapActions } from "vuex";
import WifiIcon from "@/components/icons/WifiIcon.vue";
import LastPositionsTable from "@/components/LastPositionsTable.vue";
import RouteMetricsCard from "@/components/RouteMetricsCard.vue";
import RecentRoutesTimeline from "@/components/live-global-map/components/RecentRoutesTimeline.vue";
import * as routerTypes from "@/router/router.types";
import * as actionTypes from "@/store/action-types";

export default {
  name: "GatewayInfo",

  components: {
    LastPositionsTable,
    WifiIcon,
    RouteMetricsCard,
    RecentRoutesTimeline
  },

  props: {
    anchor: {
      type: Object,
      default: () => ({})
    },
    routeList: {
      type: Array,
      default: () => [],
      required: true
    }
  },

  data() {
    return {
      activeRouteUsageInfo: null,
      anchorTotalMetrics: {},
      allRoutesUsageInfo: [],
      currentTime: "",
      currentTimeInterval: null,
      isAnchorInfoVisible: false,
      selectedAnchor: true,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    isAnchorOffline() {
      return this.checkAnchorOffline(
        this.anchor.powerSource,
        this.anchor.offlineTs
      );
    },
    backgroundColor() {
      return this.selectedAnchor || this.isAnchorInfoVisible
        ? "#FFFFFF"
        : "#F9FAF9";
    },
    lastPositionTime() {
      return (
        "(" +
        formatTimeUnit(
          this.anchor.lastPositionMetadata.lastSeenTs,
          true,
          this.clientData
        ) +
        ")"
      );
    },
    percentageValidToday() {
      return this.getPercentage(
        this.anchorTotalMetrics.validToday,
        this.anchorTotalMetrics.validToday + this.anchorTotalMetrics.errorsToday
      );
    },
    percentageErrorsToday() {
      return this.getPercentage(
        this.anchorTotalMetrics.errorsToday,
        this.anchorTotalMetrics.validToday + this.anchorTotalMetrics.errorsToday
      );
    }
  },
  methods: {
    ...mapActions("clients", {
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR
    }),
    formatTimeUnit,

    checkAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource) {
          if (moment.utc(moment.unix(offlineTs)).isBefore(sixMinutesAgo)) {
            return true;
          }
          return false;
        } else {
          const oneMinuteAgo = moment()
            .utc()
            .subtract(60, "seconds");

          if (moment.utc(moment.unix(offlineTs)).isBefore(oneMinuteAgo)) {
            return true;
          }
        }
      }
      return false;
    },
    toggleAnchorInfo() {
      this.isAnchorInfoVisible = !this.isAnchorInfoVisible;
    },
    getRouteTotalHours() {
      const currentTime = moment.tz(this.clientData.timezone);
      const routeStart = moment.tz(
        moment.unix(this.anchor.currentRouteInstance.startDate),
        this.clientData.timezone
      );

      if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
        return (
          Math.floor(
            currentTime.diff(routeStart, "seconds") / 3600
          ).toString() + "h"
        );
      else return "";
    },

    getRouteTotalMinutes() {
      const minutes = Math.floor(
        (moment()
          .utc()
          .diff(
            moment.tz(
              moment.unix(this.anchor.currentRouteInstance.startDate),
              this.clientData.timezone
            ),
            "seconds"
          ) %
          3600) /
          60
      );

      const finalMinutes = minutes < 10 ? "0" + minutes : minutes;
      return finalMinutes + "m";
    },

    getRouteTotalSeconds() {
      const secondsDiff = Math.floor(
        (moment()
          .utc()
          .diff(
            moment.tz(
              moment.unix(this.anchor.currentRouteInstance.startDate),
              this.clientData.timezone
            ),
            "seconds"
          ) %
          3600) %
          60
      );
      if (secondsDiff < 10) return "0" + secondsDiff + "s";
      else return secondsDiff + "s";
    },

    updateCurrentTime() {
      if (this.anchor.currentRouteInstance) {
        const hours = this.getRouteTotalHours();
        const minutes = this.getRouteTotalMinutes();
        const seconds = this.getRouteTotalSeconds();

        this.currentTime = hours + minutes + seconds;
      } else {
        this.currentTime = "";
      }
    },
    addRouteConstraints(route) {
      const routeInfo = this.routeList.find(
        element => element.id == route.routeId
      );
      if (routeInfo) {
        return {
          ...route,
          constraintTag: routeInfo.constraintTag,
          constraintShiftStart: routeInfo.constraintShiftStart,
          constraintShiftEnd: routeInfo.constraintShiftEnd
        };
      }
      return route;
    },
    getTotalMetrics() {
      const { routesInfo } = this.anchor.usageInfo;
      const metricsToday = {
        valid: 0,
        errors: 0,
        totalDuration: 0
      };

      routesInfo.forEach(route => {
        metricsToday.valid += route.dCount || 0;
        metricsToday.errors += route.dCountError || 0;
        metricsToday.totalDuration += route.dAvg || 0;
      });

      this.anchorTotalMetrics.validToday = metricsToday.valid;
      this.anchorTotalMetrics.errorsToday = metricsToday.errors;

      const averageDurationInSeconds = routesInfo.length
        ? Math.round(metricsToday.totalDuration / routesInfo.length) * 1000
        : 0;

      this.anchorTotalMetrics.avgDurationToday = averageDurationInSeconds
        ? moment.utc(averageDurationInSeconds).format("HH[h]mm[m]")
        : "00h00m";
    },
    handleUsageInfoChanges() {
      if (!this.routeList.length) return;

      if (this.anchor.activeRoute) {
        const activeRoute = this.anchor.usageInfo.routesInfo.find(
          route => route.routeId === this.anchor.activeRoute.id
        );

        this.activeRouteUsageInfo = this.addRouteConstraints(activeRoute);

        this.allRoutesUsageInfo = this.anchor.usageInfo.routesInfo
          .filter(route => route.routeId !== this.anchor.activeRoute.id)
          .map(this.addRouteConstraints);
      } else {
        this.allRoutesUsageInfo = this.anchor.usageInfo.routesInfo.map(
          this.addRouteConstraints
        );
        this.activeRouteUsageInfo = null;
      }
    },
    getPercentage(divisor, dividend) {
      if (dividend)
        return Number.parseFloat((divisor / dividend) * 100).toFixed(1);
      else return 0;
    },
    selectAnchor() {
      if (!this.isAnchorOffline) {
        this.selectedAnchor = !this.selectedAnchor;
      }
    },
    getLastAnchorPositionLabel(lastTagPositionId) {
      if (lastTagPositionId) {
        const tag = this.resources.data.tags.find(
          _tag => _tag.lastPosition?.id === lastTagPositionId
        );
        if (tag)
          if (tag.label) return tag.label;
          else
            return tag.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("");
      }
    },
    redirectToAnalytics() {
      this.updateSelectedAnchor(this.anchor.anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_STATS_GLOBALS
      });
    },
    redirectToDashboard() {
      this.updateSelectedAnchor(this.anchor.anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_DASHBOARD
      });
    },
    redirectToRoutesSettings() {
      this.updateSelectedAnchor(this.anchor.anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_USER_SETTINGS,
        params: {
          tab: "routes",
          anchorId: this.anchor.anchorId
        }
      });
    },
    isTruncated(anchorId) {
      const element = this.$refs[`label-${anchorId}`];
      return element && element.offsetWidth < element.scrollWidth;
    },
    onShowTagLabelOnMap(tagData) {
      this.$emit("update-tag-to-show-on-map", tagData);
    }
  },
  watch: {
    anchor: {
      deep: true,
      handler(newValue, oldValue) {
        this.getTotalMetrics();
        if (
          (newValue.activeRoute &&
            oldValue.activeRoute &&
            newValue.activeRoute.id !== newValue.activeRoute.id) ||
          (newValue.activeRoute && !oldValue.activeRoute) ||
          (oldValue.activeRoute && !newValue.activeRoute)
        ) {
          this.handleUsageInfoChanges();
        }
      }
    },
    "anchor.activeRoute": {
      deep: true,
      handler(newValue, oldValue) {
        if (
          (newValue && oldValue && newValue.id !== newValue.id) ||
          (newValue && !oldValue) ||
          (oldValue && !newValue)
        ) {
          this.getTotalMetrics();
          this.handleUsageInfoChanges();
        }
      }
    },
    isAnchorOffline: {
      handler(newValue) {
        if (!newValue) {
          this.selectedAnchor = false;
        }
      }
    },
    selectedAnchor: {
      handler(newValue) {
        if (newValue) {
          this.$emit("update-anchors-to-show", this.anchor.anchorId, "add");
        } else {
          this.$emit("update-anchors-to-show", this.anchor.anchorId, "remove");
        }
      }
    },
    routeList: {
      handler(newValue, oldValue) {
        if (newValue.length && oldValue.length === 0) {
          this.handleUsageInfoChanges();
        }
      }
    }
  },
  created() {
    this.selectedAnchor = !this.isAnchorOffline;
    this.currentTimeInterval = setInterval(this.updateCurrentTime, 2000);
    this.handleUsageInfoChanges();
    this.getTotalMetrics();
  },
  mounted() {
    this.$nextTick(() => {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    });
  },
  beforeDestroy() {
    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  }
};
</script>

<style lang="scss">
.anchor-overview {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  width: 100%;

  @media (max-width: 1800px) {
    width: 96%;
  }

  &__main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 17px 18px;

    @media (max-width: 680px) {
      gap: 10px;
    }

    .overview-left-content {
      display: flex;
      gap: 30px;
      order: 1;

      .details-buttons {
        display: flex;
        width: 22px;
        height: 95px;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 2px;

        .el-button {
          padding: 3px 5px;
          margin-left: 0;

          .el-icon-location-outline {
            color: #1f4258 !important;
            font-weight: bold;
          }
        }

        .wifi {
          height: 22px;
          svg {
            width: 30px;
            top: -5px;
            height: 30px;
            position: relative;

            // @media (max-width: $md) {
            //   left: 7px;
            //   top: -21px;
            // }
          }

          &__no-connection {
            left: 5px;
            width: 19px;
            height: 2px;
            top: 8px;

            // @media (max-width: $md) {
            //   top: 7px;
            //   left: 8px;
            // }
          }
        }
      }

      .anchor-content {
        display: flex;
        width: 164px;
        height: 95px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        &__head {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .head-details {
            display: flex;
            align-items: flex-start;
            gap: 5px;

            .info-label {
              align-self: flex-start;
              font-size: 16px;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 125px;
              color: #202426;
            }
          }
        }

        &__last-position {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 6px;

          .last-position-status {
            color: #202426;
            font-size: 14px;
            font-weight: 600;
          }
          .last-position-value {
            display: flex;
            flex-wrap: wrap;
            gap: 3px;
            color: #454947;
            font-size: 14px;
            text-align: left;

            .position-label {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 164px;
            }
          }
        }
        .last-position-value--empty {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .metrics-details {
      display: flex;
      width: 150px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      height: 100%;
      order: 2;

      @media (max-width: 680px) {
        order: 3;
      }

      @media (max-width: 420px) {
        order: 2;
      }

      .metrics-label {
        display: flex;
        align-items: center;
        height: 100%;
        color: #202426;
        font-size: 14px;
        font-weight: 600;
      }

      .metrics-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 5px;
        text-align: start;
        height: 100%;

        .metrics-value {
          font-size: 14px;
          color: #454947;
        }

        .valid {
          color: #26903c;
          font-weight: bold;
        }

        .errors {
          color: #ff0000;
          font-weight: bold;
        }
      }
    }

    .large-width {
      width: 780px;

      @media (max-width: 1200px) {
        width: 300px;
      }
      @media (max-width: 680px) {
        width: 100%;
      }
    }
    .small-width {
      width: 618px;

      @media (max-width: 1200px) {
        width: 200px;
      }
    }

    .recent-routes {
      display: flex;
      height: 95px;
      justify-content: center;
      align-items: center;
      order: 3;

      @media (max-width: 680px) {
        order: 4;
      }
      @media (max-width: 420px) {
        order: 3;
      }

      &__data {
        height: 100%;
      }

      &__no-data {
        color: #606060;
        font-size: 14px;
        font-weight: 700;
      }

      &__empty {
        display: flex;
        flex-direction: column;
        gap: 7px;
        color: #606060;
        font-weight: 700;

        .empty-title {
          font-size: 14px;
        }
        .empty-subtitle {
          cursor: pointer;
          font-size: 12px;
        }
        .empty-subtitle:hover {
          color: #202426;
        }
      }
    }

    .details-toggle {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 95px;
      font-weight: bold;
      order: 4;

      @media (max-width: 680px) {
        order: 2;
        width: 38%;
        align-items: flex-end;
      }

      @media (max-width: 420px) {
        order: 4;
        height: auto;
        width: 100%;
        align-items: center;
      }

      span {
        cursor: pointer;
      }
    }
  }

  &__expandable-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 16px 23px;

    .expandable-details-info {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title {
        font-weight: bold;
        font-size: 14px;
        text-align: left;
      }

      &__metrics {
        display: flex;
        overflow-x: auto;
        gap: 10px;

        .metrics .el-card__body .time-based-metrics .date,
        .metrics .el-card__header .content .left-content .route-label {
          font-size: 14px;
        }

        .metrics .el-card__body {
          padding: 8px 17px 13px;
        }
      }
    }
  }
}
</style>
