<template>
  <div class="routes-manager">
    <div class="routes-manager__head">
      <el-radio v-model="radioButton" label="by Routes" border>
        {{ $t("labels.byRoutes") }}
      </el-radio>
      <el-radio v-model="radioButton" label="by Gateways" border>
        {{ $t("labels.byGateways") }}
      </el-radio>
    </div>
    <div>
      <span v-if="radioButton == 'by Routes'" class="routes-manager__details">
        {{ $t("messages.routesDetailsRoutes.line1") }} <br />
        {{ $t("messages.routesDetailsRoutes.line2") }}
      </span>
      <span v-if="radioButton == 'by Gateways'" class="routes-manager__details">
        {{ $t("messages.routesDetailsGateways.line1") }} <br />
        {{ $t("messages.routesDetailsGateways.line2") }}
      </span>
    </div>
    <div class="routes-manager__content">
      <div class="routes" v-if="radioButton == 'by Routes'">
        <div class="card-layout-buttons">
          <el-tooltip :content="$t('tooltip.gridLayout')" placement="top">
            <el-button @click="changeLayout('routes', 'grid')">
              <GridIcon :isButtonActive="routesLayout === 'grid'" />
            </el-button>
          </el-tooltip>
          <el-tooltip :content="$t('tooltip.rowsLayout')" placement="top">
            <el-button @click="changeLayout('routes', 'lines')">
              <LinesIcon :isButtonActive="routesLayout === 'lines'" />
            </el-button>
          </el-tooltip>
        </div>
        <div class="card-section">
          <div class="route-cards" element-loading-background="inherit">
            <div
              v-for="route in routesToShow"
              :key="route.id"
              :ref="`route-${route.id}`"
            >
              <RouteCard
                :route="route"
                :anchorList="anchorList"
                :routeList="routeList"
                :routesLayout="routesLayout"
                :isArchivedCards="false"
                :isActiveRoute="
                  (activeRoute?.id ?? selectedAnchor.activeRoute?.id) ==
                    route.id
                "
                @update-archived-routes="updateArchivedRoutes"
                @update-tab-name="updateTabName"
                @route-updated="routeUpdated"
                @delete-route="deleteRoute"
              />
            </div>
            <!-- <div class="archived-routes">
              <span class="archived-title">Archived</span>
              <div class="route-cards">
                <div v-for="route in archivedRoutes" :key="route.id">
                  <RouteCard
                    :route="route"
                    :anchorList="anchorListAvailable"
                    :routesLayout="routesLayout"
                    :isArchivedCards="true"
                  />
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div class="button-toggle">
          <el-button
            @click="showOldRoutes = !showOldRoutes"
            v-if="!showOldRoutes"
            size="small"
            :disabled="
              !(routeList?.length && routeList.length !== newRoutes.length)
            "
            >{{ $t("actions.showMore") }}</el-button
          >
          <el-button
            @click="showOldRoutes = !showOldRoutes"
            v-else
            size="small"
            >{{ $t("actions.showLess") }}</el-button
          >
        </div>
        <div v-if="!routeList?.length" class="empty-state-message">
          <span>{{ $t("messages.emptyStateMessage") }}</span>
        </div>
      </div>
      <div class="anchors" v-if="radioButton == 'by Gateways'">
        <div class="card-layout-buttons">
          <el-tooltip :content="$t('tooltip.gridLayout')" placement="top">
            <el-button @click="changeLayout('anchors', 'grid')">
              <GridIcon :isButtonActive="anchorsLayout === 'grid'" />
            </el-button>
          </el-tooltip>
          <el-tooltip :content="$t('tooltip.rowsLayout')" placement="top">
            <el-button @click="changeLayout('anchors', 'lines')">
              <LinesIcon :isButtonActive="anchorsLayout === 'lines'" />
            </el-button>
          </el-tooltip>
        </div>
        <div class="anchor-cards">
          <div v-for="anchor in anchorList" :key="anchor.anchorId">
            <RoutesAnchorCard
              :anchor="anchor"
              :routeList="routeList"
              :anchorsLayout="anchorsLayout"
              @route-updated="routeUpdated"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteCard from "@/components/user-settings/components/UserRouteCard.vue";
import RoutesAnchorCard from "@/components/user-settings/components/UserRoutesAnchorCard.vue";
import GridIcon from "@/components/icons/GridIcon.vue";
import LinesIcon from "@/components/icons/LinesIcon.vue";
import * as actionTypes from "@/store/action-types";
import { mapState, mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  name: "RoutesManager",
  components: {
    RouteCard,
    RoutesAnchorCard,
    GridIcon,
    LinesIcon
  },
  props: {
    routeList: {
      type: Array,
      default: () => []
    },
    scrollToCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      anchorsLayout: "grid",
      archivedRoutes: [],
      routesToShow: [],
      showOldRoutes: false,
      radioButton: "by Routes",
      routesLayout: "grid",
      updatingRoutes: false,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState("user", {
      resources: "resources",
      activeRoute: "activeRoute",
      isChangingInformation: "isChangingInformation"
    }),
    ...mapState("clients", {
      selectedAnchor: "selectedAnchor"
    }),
    anchorList() {
      const anchors = this.resources.data.anchors;

      anchors.sort((a, b) => {
        if (a.activeRoute === null && b.activeRoute === null) {
          return 0;
        } else if (a.activeRoute === null) {
          return 1;
        } else if (b.activeRoute === null) {
          return -1;
        } else {
          return b.activeRoute - a.activeRoute;
        }
      });

      return anchors;
    },
    // anchorListAvailable() {
    //   const routeAnchorIds = this.routeList.flatMap(route =>
    //     route.anchors.map(anchor => anchor.id)
    //   );

    //   return this.resources.data.anchors.filter(
    //     anchor => !routeAnchorIds.includes(anchor.id)
    //   );
    // },
    updatedRouteList() {
      return this.routeList.filter(
        route => this.archivedRoutes.indexOf(route) == -1
      );
    },
    newRoutes() {
      const TWO_WEEKS_OLD_SINCE_TODAY = moment()
        .clone()
        .subtract(14, "days")
        .startOf("day");

      const routesWithAnchors = this.routeList.filter(
        route => route.anchors?.length
      );

      if (routesWithAnchors.length) {
        return routesWithAnchors;
      }

      const twoWeeksOldRoutes = this.routeList.filter(route =>
        moment.unix(route.startDate).isBefore(TWO_WEEKS_OLD_SINCE_TODAY)
      );

      if (twoWeeksOldRoutes.length) {
        return twoWeeksOldRoutes;
      }

      return this.routeList.length < 4
        ? this.routeList
        : this.routeList.slice(0, 3);
    }
  },
  watch: {
    selectedAnchor: {
      handler(newValue) {
        this.updateActiveRoute(newValue.activeRoute ?? null);
      }
    },
    scrollToCard: {
      handler(newValue) {
        if (newValue) {
          this.scrollToActiveRoute();
        }
      }
    },
    showOldRoutes: {
      handler(newValue) {
        if (newValue) {
          this.routesToShow = this.routeList;
        } else {
          this.routesToShow = this.newRoutes;
          let isActiveNewRoute = false;
          if (this.activeRoute) {
            isActiveNewRoute = this.routesToShow.find(
              route => route.id === this.activeRoute.id
            );
          }
          if (!isActiveNewRoute && this.newRoutes.length) {
            this.updateActiveRoute(this.newRoutes[this.newRoutes.length - 1]);
          }
        }
      }
    },
    newRoutes: {
      handler(newValue) {
        if (newValue.length && this.routesToShow.length === 0) {
          this.routesToShow = newValue;
        }
      }
    },
    routeList: {
      handler() {
        if (this.showOldRoutes) {
          this.routesToShow = this.routeList;
        } else {
          this.routesToShow = this.newRoutes;
        }
      }
    },
    activeRoute: {
      handler(newValue) {
        if (newValue) {
          const isNewRoute = this.newRoutes.find(
            route => route.id === newValue.id
          );
          if (!isNewRoute) {
            this.showOldRoutes = true;
          }
        }
      }
    },
    radioButton: {
      handler(newValue) {
        if (newValue === "by Gateways") {
          this.updateActiveRoute(null);
        }
      }
    },
    "$route.params.anchorId": {
      handler(newValue) {
        if (newValue) {
          this.radioButton = "by Gateways";
        }
      }
    },
    windowWidth(newValue) {
      if (newValue < 768) {
        this.routesLayout = "lines";
        this.anchorsLayout = "lines";
      }
    }
  },
  methods: {
    ...mapActions("user", {
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),
    changeLayout(layoutToChange, layout) {
      layoutToChange === "routes"
        ? (this.routesLayout = layout)
        : (this.anchorsLayout = layout);
    },
    updateArchivedRoutes(routeToUpdate) {
      const foundRoute = this.archivedRoutes.find(
        route => route.id === routeToUpdate.id
      );

      foundRoute
        ? (this.archivedRoutes = this.archivedRoutes.filter(
            route => route.id !== routeToUpdate.id
          ))
        : this.archivedRoutes.push(routeToUpdate);
    },
    updateTabName(tabName, routeId) {
      this.$emit("update-tab-name", tabName, routeId);
    },
    deleteRoute(routeId, routeLabel) {
      this.$emit("delete-route", routeId, routeLabel);
    },
    routeUpdated() {
      this.$emit("fetch-route-list");
    },
    scrollToActiveRoute() {
      if (this.activeRoute && this.radioButton === "by Routes") {
        const card = this.$refs[`route-${this.activeRoute.id}`][0];
        if (card) {
          card.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  },
  created() {
    if (this.$route.params.anchorId) {
      this.radioButton = "by Gateways";
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>

<style lang="scss">
.routes-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding-top: 24px;

  &__head {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding-bottom: 10px;

    .el-radio {
      background-color: #fff;
      border-radius: 5px;
      margin: 0;

      &__label {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-radio.is-bordered {
      padding: 10px 15px 12px;
      margin: 0 !important;
    }
  }

  &__details {
    font-size: 14px;
    font-weight: bold;
  }

  &__content {
    width: 100%;

    .routes,
    .anchors {
      display: flex;
      flex-direction: column;
      width: 100%;

      .card-section {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 445px);
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;
        padding: 10px;

        @media (max-width: $md) {
          max-height: 100%;
        }
        .route-cards,
        .old-routes {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          gap: 30px;
        }
      }

      .button-toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }

      .empty-state-message {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        font-weight: bold;
      }

      // .archived-routes {
      //   display: flex;
      //   flex-direction: column;
      //   gap: 15px;

      //   .archived-title {
      //     display: none;
      //     font-weight: bold;
      //     text-align: left;
      //     padding: 20px;
      //   }
      // }

      .anchor-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 10px;
        max-height: 56vh;
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;

        @media (max-width: $sm) {
          max-height: calc(100vh - 386px);
        }
      }

      .card-layout-buttons {
        display: flex;
        justify-content: flex-end;
        justify-self: flex-end;
        padding: 0 48px 5px;
        width: auto;

        @media (max-width: $sm) {
          display: none;
        }

        .el-button {
          background-color: inherit;
          border: none;
          padding: 0;
        }

        .el-button:hover {
          color: aqua;
        }
      }
    }
  }
}
</style>
