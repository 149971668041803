import { dashboardStore } from "@/modules/dashboard/store";
import { HttpRequest } from "@/services/api/http";

export default new (class AuthApi extends HttpRequest {
  constructor() {
    super();
  }

  public async createUser(data: any) {
    return this.axiosClient.post("/users/", data);
  }

  public async checkUserTokens(token: string) {
    return this.axiosClient.get(`/users/check_user_token/?token=${token}`);
  }

  public async editUser(id: number, data: any) {
    return this.axiosClient.put(`/users/edit/${id}`, data);
  }

  public async updateUser(newPassword: string, userId: number) {
    return this.axiosClient.put(`/users/${userId}`, {
      password: newPassword
    });
  }

  public async deleteUser(userId: number) {
    return this.axiosClient.delete(`/users/${userId}`);
  }

  public async getUsageInfo(clientId: number) {
    return this.axiosClient.get(
      `/users/resource/usage_info?client_id=${clientId}`
    );
  }

  public async getResources(timestamp?: number, simplified?: boolean) {
    if (timestamp) {
      if (simplified)
        return this.axiosClient.get(`/users/resources/${timestamp}/simplified`);
      else return this.axiosClient.get(`/users/resources/${timestamp}`);
    } else {
      return this.axiosClient.get(`/users/resources/`);
    }
  }

  public async getUserAnchors() {
    return this.axiosClient.get(`/users/anchors/`);
  }

  public async updateUserLang(userId: number, newLanguage: string) {
    return this.axiosClient.put(`/users/${userId}`, {
      dashboardLanguage: newLanguage
    });
  }
})();
