var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticClass:"expandable-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.anchorsData,"border":""},on:{"row-click":data => _vm.$emit('row-click', data)}},[_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("labels.noData"))+" ")]),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"expandable-table__signal"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.signal"))+":")]),_c('WifiIcon',{attrs:{"signal":scope.row.rssi,"noConnection":scope.row.lastHeartbeat
              ? _vm.isAnchorOffline(
                  scope.row.lastHeartbeat.powerSource,
                  scope.row.offlineTs
                )
              : true}})],1),(scope.row.label)?_c('p',{staticClass:"expandable-table__id"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.idDecimal"))+":")]),_vm._v(" "+_vm._s(scope.row.anchorId)+" ")]):_vm._e(),(_vm.isClientDefault)?_c('p',{staticClass:"expandable-table__travel-rate"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.travelRate"))+":")]),_vm._v(" "+_vm._s(_vm.getTravelRate(scope.row.anchorId, "today"))+" ("+_vm._s(_vm.$t("labels.today"))+") "+_vm._s(_vm.getTravelRate(scope.row.anchorId, "last-two-weeks"))+" ("+_vm._s(_vm.$t("labels.lastTwoWeeks"))+") ")]):_vm._e(),_c('p',{staticClass:"expandable-table__mac-address"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.macAddress"))+":")]),_vm._v(" "+_vm._s(scope.row.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" ")]),_c('p',{staticClass:"expandable-table__bssid"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.bssid"))+":")]),_vm._v(" "+_vm._s(scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssid : "")+" ")]),_c('p',{staticClass:"expandable-table__ssid-name"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.ssidName"))+":")]),_vm._v(" "+_vm._s(scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssidName ? scope.row.lastHeartbeat.ssidName : _vm.$t("labels.na") : _vm.$t("labels.na"))+" ")]),_c('p',{staticClass:"expandable-table__firmware-version"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.fwVersion"))+":")]),_vm._v(" "+_vm._s(_vm.getFirmwareVersion(scope.row.firmwareId))+" ")]),_c('p',{class:_vm.onOffClasses(
            'power-source',
            scope.row.lastHeartbeat ? scope.row.lastHeartbeat.powerSource : ''
          )},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.power"))+":")]),_c('img',{attrs:{"src":require("@/assets/power_socket.svg"),"alt":_vm.$t('alt.powerSource')}})]),_c('p',{staticClass:"expandable-table__last-seen"},[_c('b',[_vm._v(_vm._s(_vm.$t("labels.lastSeen"))+":")]),(scope.row.lastHeartbeat)?_c('span',{style:({ color: _vm.computedColor(scope.row) })},[_vm._v(" "+_vm._s(_vm.moment .tz( _vm.moment.unix(scope.row.lastHeartbeat.createdDate), _vm.clientData.timezone ) .format("HH:mm, DD/MM/YYYY"))+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.moment .unix(scope.row.lastHeartbeat.createdDate) .format("HH:mm, DD/MM/YYYY"))+" ")])]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$tc('labels.gateway', 1)},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label ? scope.row.label : scope.row.id .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" ")]}}])}),(_vm.isAdmin)?_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.operations'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit-buttons"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_c('i',{staticClass:"el-icon-edit"})])],1)]}}],null,false,3187114737)}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }