<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  name: "AssetBarChart",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    typeOfDataSet: {
      type: String,
      default: "average"
    }
  },

  watch: {
    data(newValue) {
      this.updateChart(newValue, this.typeOfDataSet);
    },
    "$i18n.locale"() {
      this.updateChart(this.data, this.typeOfDataSet);
    }
  },

  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t("labels.timeSpendInStore"),
            data: [],
            backgroundColor: "#1F4258"
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("labels.averageTimeFormat")
              },
              stacked: true,
              ticks: {
                stepSize: 20
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: this.$t("labels.hourFormat")
              },
              ticks: {
                autoSkip: false,
                maxTicksLimit: 40
              }
            }
          ]
        }
      }
    };
  },
  methods: {
    formatHour(hour) {
      const formattedHour = hour
        .toFixed(2)
        .split(".")
        .join(":");
      if (hour < 10) {
        return "0" + formattedHour;
      }
      return formattedHour;
    },
    updateChart(data, typeOfDataSet) {
      if (typeOfDataSet == "laps") {
        this.chartData.datasets[0].label = this.$t("labels.customersPerHour");
        this.options.scales.yAxes[0].scaleLabel.labelString = this.$t(
          "labels.numberOfCustomers"
        );
        this.chartData.datasets[0].data = data.map(elem => elem.totalTags);
      } else if (typeOfDataSet == "average") {
        this.chartData.datasets[0].label = this.$t("labels.timeSpendInStore");
        this.options.scales.yAxes[0].scaleLabel.labelString = this.$t(
          "labels.averageTimeFormat"
        );
        this.chartData.datasets[0].data = data.map(elem =>
          Math.round(elem.avgDuration / 60)
        );
      }
      this.options.scales.xAxes[0].scaleLabel.labelString = this.$t(
        "labels.hourFormat"
      );
      this.chartData.labels = data.map(elem => this.formatHour(elem.hour));

      this.renderChart(this.chartData, this.options);
    }
  },

  mounted() {
    this.updateChart(this.data, this.typeOfDataSet);
  }
};
</script>
