<template>
  <div class="cargo-check">
    <h2 class="cargo-check__title">{{ $t("title.cargoCheckDemo") }}</h2>
    <div class="cargo-check__content">
      <div class="cargo-check__content__anchor-card">
        <div class="cargo-check__content__anchor-card__head">
          <span class="cargo-check__content__anchor-card__label"
            ><b>{{
              this.anchor ? this.anchor.label : "------------------"
            }}</b></span
          >
          <span class="cargo-check__content__anchor-card__id"
            ><b>{{ $t("labels.quantity") }}: </b>{{ activeTagsQuantity }}
            <span :style="styleActiveTagsPercentual"
              >({{ activeTagsPercentual }})</span
            >
          </span>
        </div>
        <div class="cargo-check__content__anchor-card__tags-table">
          <TagTable :tagsData="activeTags" />
        </div>
      </div>
      <div class="cargo-check__content__inactive-tags-card">
        <div class="cargo-check__content__inactive-tags-card__head">
          <span
            ><b>{{ $t("labels.inactiveBeacons") }}</b></span
          >
          <span
            ><b>{{ $t("labels.quantity") }}: </b>{{ inactiveTagsQuantity }}
            <span :style="styleInactiveTagsPercentual"
              >({{ inactiveTagsPercentual }})</span
            >
          </span>
        </div>
        <div class="cargo-check__content__inactive-tags-card__tags-table">
          <TagTable :tagsData="inactiveTags" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";
import TagTable from "@/components/TagTable.vue";

export default {
  name: "CargoCheck",
  components: {
    TagTable
  },

  data() {
    return {
      anchor: null,
      tagsList: [],
      interval: null
    };
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    activeTags() {
      return this.tagsList.filter(tag => tag.isRecent);
    },
    inactiveTags() {
      return this.tagsList.filter(tag => !tag.isRecent);
    },
    activeTagsQuantity() {
      return this.activeTags.length;
    },
    inactiveTagsQuantity() {
      return this.inactiveTags.length;
    },
    activeTagsPercentual() {
      return this.calculatePercentage(this.activeTagsQuantity, this.totalTags);
    },
    inactiveTagsPercentual() {
      return this.calculatePercentage(
        this.inactiveTagsQuantity,
        this.totalTags
      );
    },
    styleActiveTagsPercentual() {
      return this.getStyle(this.activeTagsPercentual, "#0bd50b");
    },
    styleInactiveTagsPercentual() {
      return this.getStyle(this.inactiveTagsPercentual, "red");
    },
    totalTags() {
      return this.activeTagsQuantity + this.inactiveTagsQuantity;
    }
  },

  methods: {
    async getAnchorTagsList() {
      try {
        const response = await tagsApi.getAnchorTagsList();
        this.tagsList = response.data;
      } catch (error) {
        console.log("Error fetching tags list:", error);
      }
    },
    calculatePercentage(quantity, total) {
      return total > 0 ? Math.floor((quantity * 100) / total) + "%" : "0%";
    },
    getStyle(percentual, color) {
      return percentual === "100%" ? `color: ${color}; font-weight: 600;` : "";
    }
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  created() {
    this.interval = setInterval(this.getAnchorTagsList, 2000);
    this.anchor = this.resources.data.anchors.length
      ? this.resources.data.anchors[0]
      : null;
  }
};
</script>

<style lang="scss" scoped>
.cargo-check {
  padding-top: 60px;
  padding-bottom: 20px;
  background: $--border-color-extra-light;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 2px solid #dcdfe6;
  z-index: 10;
  scrollbar-gutter: stable both-edges;

  &__title {
    margin: 24px 0;
  }

  &__content {
    display: grid;
    gap: 12px;
    justify-content: center;
    gap: 60px;
    grid-template-columns: 35% 35%;

    @media (max-width: $md) {
      display: flex;
      flex-direction: column;
      margin: 0 5%;
      gap: 30px;
    }

    &__anchor-card {
      display: flex;
      flex-direction: column;
      background-color: white;
      height: max-content;

      &__head {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ddd;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
          0 0 6px 0 rgba(0, 0, 0, 0.04);
        border-radius: 3px;
        line-height: 32px;
        padding: 24px;
        position: relative;
        text-align: left;
        z-index: 1;

        &__label,
        &__id {
          max-width: max-content;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__tags-table {
        padding: 0px 30px 30px;
        margin: 0 5px;
      }
    }

    &__inactive-tags-card {
      &__head {
        display: flex;
        justify-content: space-between;
        height: max-content;
        background-color: #00000014;
        border: 1px solid #ddd;
        border-radius: 3px;
        line-height: 32px;
        padding: 24px;
        position: relative;
        text-align: left;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12),
          0 0 6px 0 rgba(0, 0, 0, 0.04);
        z-index: 1;
      }
      &__tags-table {
        padding: 0px 30px 30px;
        background-color: white;
        margin: 0 5px;
      }
    }
  }
}
</style>
