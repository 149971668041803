var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isLiveMap && this.clientData.mapImages.length)?_c('aside',{staticClass:"dashboard__sidebar"},[(!_vm.isEditing && !_vm.stateAnchors.isEmpty)?_c('div',{class:_vm.isTracker ? 'sidebar-wrapper__tracker' : 'sidebar-wrapper'},[(!_vm.isTracker)?[_c('h3',[_vm._v(_vm._s(_vm.$t("labels.realTimeMetrics")))]),(_vm.sidebarAnchor.usageInfo)?[(_vm.sidebarAnchor.usageInfo.routesInfo.length > 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingMetricsCard),expression:"loadingMetricsCard"}],staticClass:"wrapper",attrs:{"element-loading-background":"#F0F5FB"}},[(_vm.activeRouteUsageInfo)?_c('div',[_c('RouteMetricsCard',{attrs:{"usageInfo":_vm.activeRouteUsageInfo,"isActiveRoute":true}})],1):_vm._e(),_vm._l((_vm.allRoutesUsageInfo),function(routesInfo){return _c('div',{key:routesInfo.routeId},[_c('RouteMetricsCard',{attrs:{"usageInfo":routesInfo,"isActiveRoute":false}})],1)})],2):_vm._e()]:_vm._e(),_c('div',{staticClass:"sidebar-component"},[_c('h3',[_vm._v(_vm._s(_vm.$t("labels.recentLaps")))])]),(_vm.sidebarAnchor)?_c('div',{staticClass:"sidebar__table"},[_c('el-table',{staticClass:"sidebar-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.sidebarAnchor.activeRouteOnTime
              ? [''].concat(_vm.sidebarAnchor.recentRoutes)
              : _vm.sidebarAnchor.recentRoutes},on:{"row-click":_vm.redirect}},[_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("labels.noData"))+" ")]),_c('el-table-column',{attrs:{"label":"","width":"35px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.error === 1)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.routeTimeout'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e(),(
                  scope.row.error === 2 ||
                    scope.row.error === 3 ||
                    scope.row.error === 5
                )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.routeWrongPath'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e(),(scope.row.error === 4)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.routeOffline'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e(),(scope.row.error === 6)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.routeInterrupted'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e(),(scope.row.error === 7)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.routeMinDuration'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e(),(scope.row.error > 7)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tooltip.unknownError'),"placement":"right"}},[(scope.row.error)?_c('i',{staticClass:"el-icon-warning"}):_vm._e()]):_vm._e()]}}],null,false,1606307615)}),_c('el-table-column',{staticClass:"route-column",attrs:{"label":_vm.$tc('labels.route', 1)},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":scope.$index === 0 &&
                  _vm.sidebarAnchor.currentRouteInstance &&
                  _vm.sidebarAnchor.activeRouteOnTime
                    ? _vm.currentRouteInstanceLabel
                    : scope.row.routeLabel,"placement":"left","disabled":!_vm.isTruncated(scope)}},[(
                    scope.$index === 0 &&
                      _vm.sidebarAnchor.currentRouteInstance &&
                      _vm.sidebarAnchor.activeRouteOnTime
                  )?[_c('b',{ref:`cell-${scope.$index}`},[_vm._v(" "+_vm._s(_vm.currentRouteInstanceLabel)+" ")])]:[_c('span',{ref:`cell-${scope.$index}`},[_vm._v(" "+_vm._s(scope.row.routeLabel)+" ")])]],2)]}}],null,false,1798131570)}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.start'),"resizable":false,"width":"95px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.$index === 0 &&
                    _vm.sidebarAnchor.currentRouteInstance &&
                    _vm.sidebarAnchor.activeRouteOnTime
                )?[_c('b',[_vm._v(" "+_vm._s(_vm.formatTimeUnit( _vm.sidebarAnchor.currentRouteInstance.startDate, true, _vm.clientData ))+" ")])]:(scope.row != '')?[(_vm.anotherDay(scope.row.startTime))?[_c('el-tooltip',{attrs:{"content":_vm.formatTimeUnit(scope.row.startTime, true, _vm.clientData) +
                        ' (' +
                        _vm.moment
                          .tz(
                            _vm.moment.unix(scope.row.startTime),
                            _vm.clientData.timezone
                          )
                          .format('DD/MM') +
                        ')',"placement":"top"}},[_c('span',[_vm._v(" "+_vm._s(_vm.formatTimeUnit( scope.row.startTime, true, _vm.clientData ) + " (" + _vm.moment .tz( _vm.moment.unix(scope.row.startTime), _vm.clientData.timezone ) .format("DD/MM") + ")")+" ")])])]:[_vm._v(" "+_vm._s(_vm.formatTimeUnit(scope.row.startTime, true, _vm.clientData))+" ")]]:_vm._e()]}}],null,false,1618625930)}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.duration'),"resizable":false,"width":"95px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.$index === 0 &&
                    _vm.sidebarAnchor.currentRouteInstance &&
                    _vm.sidebarAnchor.activeRouteOnTime
                )?[_c('b',[_vm._v(_vm._s(_vm.currentTime)+" ")])]:[_vm._v(" "+_vm._s(_vm.formatTimeUnit(scope.row.duration, false, _vm.clientData))+" ")]]}}],null,false,220232718)}),_c('el-table-column',{attrs:{"label":_vm.$t('labels.delay'),"resizable":false,"width":"95px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                  scope.$index === 0 &&
                    _vm.sidebarAnchor.activeRouteOnTime &&
                    !scope.row.error
                )?[_c('b',[_vm._v(_vm._s(_vm.getRouteTableDelay))])]:(!scope.row.error)?[_vm._v(" "+_vm._s(scope.row.delay > 0 ? _vm.formatTimeUnit(scope.row.delay, false, _vm.clientData) : "-" + _vm.formatTimeUnit(scope.row.delay, false, _vm.clientData))+" ")]:[_c('hr')]]}}],null,false,1640757759)})],2)],1):_vm._e(),(_vm.lastFive)?_c('div',{staticClass:"sidebar-component"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("labels.lastFivePositions"))+" ")]),_c('div',{staticClass:"sidebar__table"},[_c('LastPositionsTable',{attrs:{"border":"none","lastFivePositions":_vm.lastFive.lastFive},on:{"show-tag-label-on-map":_vm.showTagLabelOnMap}})],1)]):_vm._e(),_c('div',{staticClass:"button-div"},[_c('el-button',{on:{"click":function($event){return _vm.redirectToPositions()}}},[_vm._v(_vm._s(_vm.$t("actions.showMore")))])],1)]:[_c('TagList',{attrs:{"asset-list":_vm.tagBeaconList,"beacon-list":_vm.beaconList,"gatewayLabel":_vm.sidebarAnchor.label,"currentTagStartTs":_vm.sidebarAnchor.currentTagStartTs},on:{"view-history":_vm.fetchAssetHistory,"highlight-beacon":_vm.updateHighlightedBeacon}})]],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }