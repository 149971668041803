<template>
  <el-dialog
    :title="$t('dialogs.saveChanges.title')"
    :width="width"
    :visible="isVisible"
  >
    <span class="warning">{{ $t("dialogs.saveChanges.line1") }} <br /> </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel-save')" size="small">{{
        $t("button.continueEditing")
      }}</el-button>
      <el-button type="primary" @click="$emit('confirm-save')" size="small">{{
        $t("button.saveChanges")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "500px"
    }
  }
};
</script>

<style lang="scss" scoped></style>
