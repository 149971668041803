<template>
  <el-table :data="tagsData" class="custom-table">
    <template slot="empty">
      {{ $t("labels.noData") }}
    </template>
    <el-table-column
      prop="label"
      :label="$t('labels.label').toUpperCase()"
      min-width="100"
      sortable
    >
      <template slot-scope="scope">
        <template v-if="scope.row.label">
          {{ scope.row.label }}
        </template>
        <template v-else>
          <hr />
        </template>
      </template>
    </el-table-column>
    <el-table-column
      prop="tag_id"
      :label="$t('labels.beaconId').toUpperCase()"
      width="150"
      sortable
    >
      <template slot-scope="scope">
        {{ toHex(scope.row.tagId) }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TagTable",
  props: {
    tagsData: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    })
  },
  methods: {
    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    }
  }
};
</script>
