<template>
  <div class="last-positions-table">
    <el-table
      :data="lastFivePositions"
      :style="tableStyle"
      @cell-click="showTagLabelOnMap"
    >
      <template slot="empty">
        {{ $t("labels.noData") }}
      </template>
      <el-table-column :label="$t('labels.start')" align="center" width="140">
        <template slot-scope="scope">
          {{ formatTimeUnit(scope.row.enterTs, true, clientData) }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('labels.duration')" align="center">
        <template slot-scope="scope">
          {{
            formatTimeUnit(
              scope.row.lastSeenAt - scope.row.enterTs,
              false,
              clientData
            )
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="label"
        :label="$tc('labels.position', 1)"
        align="center"
        width="220"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.label">{{ scope.row.label }}</span>
          <span v-else>{{ toHex(scope.row.tagId) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- <span
      v-if="$route.name === 'live-global-map' && lastFivePositions.length"
      class="last-positions-table-subtitle"
    >
      Click a row to show the position on the map.
    </span> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatTimeUnit } from "@/utils/utils";

export default {
  name: "LastPositionsTable",

  data() {
    return {
      upHere: false
    };
  },

  props: {
    lastFivePositions: {
      type: Array,
      default: () => []
    },
    width: {
      type: String,
      default: "auto"
    },
    borderRadius: {
      type: String,
      default: "5px"
    },
    border: {
      type: String,
      default: "1px solid #E5E5E5"
    },
    cursor: {
      type: String,
      default: "pointer"
    }
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    tableStyle() {
      return `width: ${this.width}; border-radius: ${this.borderRadius}; border: ${this.border}; cursor: ${this.cursor}`;
    }
  },

  methods: {
    formatTimeUnit,
    toHex(str, type) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join(type === "anchor" ? ":" : "");
    },
    showTagLabelOnMap(row) {
      const tagData = {
        enterTs: row.enterTs,
        label: row.label,
        lastSeenAt: row.lastSeenAt,
        tagId: row.tagId
      };
      this.$emit("show-tag-label-on-map", tagData);
    }
  }
};
</script>
<style lang="scss">
.last-positions-table {
  display: flex;
  flex-direction: column;
  gap: 5px;

  // .last-positions-table-subtitle {
  //   font-size: 12px;
  //   font-weight: bold;
  //   text-align: left;
  //   color: #545454;
  // }
  .el-table .el-table__cell {
    padding: 5px 0;
  }
}
</style>
