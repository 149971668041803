<template>
  <div class="select-locale">
    <el-select v-model="$i18n.locale" @change="updateUserLang">
      <el-option
        v-for="(lang, i) in langs"
        :key="`lang-${i}`"
        :value="lang"
        :label="lang.toUpperCase()"
      >
        {{ lang.toUpperCase() }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
import userApi from "@/modules/user/api/user.api";
import { mapState } from "vuex";

export default {
  name: "SelectLocale",
  data() {
    return { langs: ["en", "es", "pt", "sk"] };
  },
  computed: {
    ...mapState("auth", {
      user: "user"
    })
  },
  watch: {
    "user.data.dashboardLanguage"(newValue) {
      this.$i18n.locale = newValue || "en";
    }
  },
  methods: {
    async updateUserLang(newLang) {
      try {
        const res = await userApi.updateUserLang(this.user.data.id, newLang);
        if (res.data) {
          this.$notify({
            title: this.$t("statusMessages.success"),
            message: this.$t("messages.langUpdated"),
            type: "success"
          });
        }
      } catch (e) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      }
    }
  },
  created() {
    this.$i18n.locale = this.user.data.dashboardLanguage || "en";
  }
};
</script>

<style lang="scss">
.select-locale {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;

  .el-select {
    .el-input__inner {
      background-color: $--color-primary;
      border: none;
      color: #ffffff;
    }
    .el-input__suffix-inner .el-input__icon {
      color: #ffffff;
    }
  }
}
</style>
