<template>
  <div class="user-routes">
    <div v-if="!clientData.mapImages.length">
      <h4>
        {{ $t("messages.noLayoutAssociated") }}
      </h4>
    </div>
    <div class="container-routes" v-else>
      <div>
        <LiveMap
          :show-anchors="false"
          :enumerate-tags="
            activeTabName === 'editor'
              ? editTableData
              : activeTabName === 'creator'
              ? createTableData
              : activeRoute?.tags ?? []
          "
          :interval-update="false"
          :is-editing-route="true"
          :show-numbered-only="showNumberedOnly"
          @tag-clicked="onTagClicked"
        />
      </div>
      <div
        class="routes-tabs"
        v-loading="loadingRoutesPage"
        element-loading-background="#FFFFFF"
      >
        <div>
          <el-tabs v-model="activeTabName" @tab-click="scrollToActiveRoute">
            <el-tab-pane :label="$t('actions.manage')" name="manage">
              <RoutesManager
                :routeList="routeList"
                :scrollToCard="scrollToCard"
                @update-tab-name="updateTabName"
                @fetch-route-list="fetchRouteList"
                @set-dialog-discard="setDialogDiscard"
                @delete-route="deleteRoute"
              />
            </el-tab-pane>
            <el-tab-pane :label="$t('actions.edit')" name="editor">
              <RoutesEditor
                :routeList="routeList"
                :tableData="editTableData"
                :editingRoute="editingRoute"
                :discardEditRoute="discardEditRoute"
                :routeToEdit="selectedRoute"
                :activeTabName="activeTabName"
                @fetch-route-list="fetchRouteList"
                @change-table-data="changeEditTableData"
                @set-dialog-save="setDialogSave"
                @set-dialog-discard="setDialogDiscard"
                @update-editing-route="editingRoute = false"
                @update-discard-edit-route="discardEditRoute = false"
              />
            </el-tab-pane>
            <el-tab-pane :label="$t('actions.create')" name="creator">
              <RoutesCreator
                :routeList="routeList"
                :tableData="createTableData"
                :creatingNewRoute="creatingNewRoute"
                :discardCreateRoute="discardCreateRoute"
                @fetch-route-list="fetchRouteList"
                @change-table-data="changeCreateTableData"
                @set-dialog-save="setDialogSave"
                @set-dialog-discard="setDialogDiscard"
                @update-creating-new-route="creatingNewRoute = false"
                @update-discard-create-route="discardCreateRoute = false"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <SaveChangesDialog
      :isVisible="certifyEditTagDialogSave"
      @cancel-save="certifyEditTagDialogSave = false"
      @confirm-save="confirmSave"
    />

    <el-dialog
      :title="$t('dialogs.deleteRoute.title') + routeToDelete.label"
      :visible.sync="certifyDeleteRoute"
      :close-on-click-modal="false"
      @close="certifyDeleteRoute = false"
      width="500px"
    >
      <span class="warning"
        >{{ $t("dialogs.deleteRoute.line1") }}
        <br />
        {{ $t("dialogs.deleteRoute.line2") }} '{{
          routeToDelete?.label ?? "Route " + routeToDelete?.id
        }}'? <br />
        <br />
      </span>
      <span>{{ $t("dialogs.deleteRoute.line3") }} </span>
      <el-input
        class="delete-input"
        placeholder="Delete"
        v-model="typeDelete"
        v-loading="loadingDelete"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certifyDeleteRoute = false" size="small">{{
          $t("button.cancel")
        }}</el-button>
        <el-button
          :disabled="typeDelete != 'Delete'"
          type="danger"
          @click="deleteRouteConfirmed"
          size="small"
          >{{ $t("button.delete") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import LiveMap from "@/views/LiveMap.vue";
import RoutesManager from "@/components/user-settings/components/UserRoutesManager.vue";
import RoutesEditor from "@/components/user-settings/components/UserRoutesEditor.vue";
import RoutesCreator from "@/components/user-settings/components/UserRoutesCreator.vue";
import SaveChangesDialog from "@/components/SaveChangesDialog.vue";
import RoutesApi from "@/modules/routes/api/routes.api";

export default {
  name: "UserRoutes",

  components: {
    LiveMap,
    RoutesManager,
    RoutesEditor,
    RoutesCreator,
    SaveChangesDialog
  },

  props: {
    discardRoutesChanges: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activeTabName: "manage",
      certifyDeleteRoute: false,
      certifyEditTagDialogSave: false,
      certifyEditTagDialogDiscard: false,
      createTableData: [],
      creatingNewRoute: false,
      discardCreateRoute: false,
      discardEditRoute: false,
      editingRoute: false,
      editTableData: [],
      loadingDelete: false,
      loadingRoutesPage: true,
      routeList: [],
      scrollToCard: false,
      selectedRoute: null,
      showNumberedOnly: false,
      routeToDelete: {},
      typeDelete: ""
    };
  },

  computed: {
    ...mapState("clients", {
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources",
      isChangingInformation: "isChangingInformation",
      activeRoute: "activeRoute"
    }),
    ...mapGetters("auth", ["isAdmin"])
  },

  watch: {
    activeTabName: {
      handler(newValue) {
        this.$emit("update-discard-changes", true);
        if (newValue === "creator") {
          this.updateActiveRoute(null);
        } else if (!this.activeRoute) {
          this.updateActiveRoute(this.selectedAnchor?.activeRoute ?? null);
        }
        this.setIsChanging(false);
      }
    },
    discardRoutesChanges(newValue) {
      if (newValue) {
        if (this.activeTabName === "editor") {
          this.discardEditRoute = true;
          this.$emit("update-discard-changes", false);
        } else if (this.activeTabName === "creator") {
          this.discardCreateRoute = true;
          this.$emit("update-discard-changes", false);
        }
      }
    }
  },

  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH,
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),
    async confirmSave() {
      if (this.activeTabName === "editor") {
        this.editingRoute = true;
      } else if (this.activeTabName === "creator") {
        this.creatingNewRoute = true;
      }
      this.certifyEditTagDialogSave = false;
    },

    hasBeenPushed(id) {
      const tableData =
        this.activeTabName === "editor"
          ? this.editTableData
          : this.activeTabName === "creator"
          ? this.createTableData
          : [];
      if (tableData.length > 0)
        if (tableData[tableData.length - 1].id === id) return true;

      return false;
    },

    onTagClicked(tag) {
      if (!this.isAdmin) {
        return;
      }
      if (this.activeTabName === "editor") {
        if (!this.editTableData) {
          this.editTableData = [];
        }
        if (!this.hasBeenPushed(tag.id) && tag.parentId == null) {
          if (this.editTableData.length === 1)
            this.startingBeacon = tag.lastPosition.id;
          this.setIsChanging(true);
        }
      } else if (this.activeTabName === "creator") {
        if (!this.createTableData) {
          this.createTableData = [];
        }
        if (!this.hasBeenPushed(tag.id) && tag.parentId == null) {
          this.createTableData.push({
            id: tag.id,
            tagId: tag.tagId,
            label: tag.label,
            tagPositionId: tag.lastPosition.id,
            ord: this.createTableData.length + 1
          });
          if (this.createTableData.length === 1)
            this.startingBeacon = tag.lastPosition.id;
          this.setIsChanging(true);
        }
      }
    },

    async fetchRouteList() {
      try {
        const res = await RoutesApi.getRouteList();
        this.routeList = res.data;
        this.routeList.sort((a, b) => {
          if (b.anchors.length !== a.anchors.length)
            return b.anchors.length - a.anchors.length;
          else return b.startDate - a.startDate;
        });
      } catch (error) {
        console.log(error);
      }
    },

    updateTabName(tabName, routeId) {
      this.selectedRoute = routeId;
      this.activeTabName = tabName;
    },

    changeCreateTableData(newTableData) {
      this.createTableData = newTableData;
    },

    changeEditTableData(newTableData) {
      this.editTableData = newTableData;
    },

    deleteRoute(routeId, routeLabel) {
      this.certifyDeleteRoute = true;
      this.routeToDelete = {
        id: routeId,
        label: routeLabel
      };
    },

    async deleteRouteConfirmed() {
      try {
        const res = await RoutesApi.deleteRoute(this.routeToDelete.id);

        if (res.data) {
          this.loadingDelete = true;
          await this.fetchRouteList();
        }
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.loadingDelete = false;
        this.certifyDeleteRoute = false;
        this.$notify({
          title: this.$t("statusMessages.success"),
          message: this.$t("messages.routeDeleted"),
          type: "success"
        });
        this.updateActiveRoute(null);
      }
    },

    setDialogSave(booleanValue) {
      this.certifyEditTagDialogSave = booleanValue;
    },

    setDialogDiscard(booleanValue) {
      this.$emit("update-unsaved-changes-dialog", booleanValue);
    },

    scrollToActiveRoute() {
      if (this.activeTabName == "manage") {
        this.scrollToCard = true;
      } else {
        this.scrollToCard = false;
      }
    }
  },

  async created() {
    await this.fetchRouteList();
    this.loadingRoutesPage = false;
    this.setIsChanging(false);
    this.updateActiveRoute(this.selectedAnchor?.activeRoute ?? null);
  }
};
</script>

<style lang="scss">
.user-routes {
  height: 100%;

  .container-routes {
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: 1230px) {
      display: flex;
      flex-direction: column;
    }
  }

  .dashboard {
    max-height: calc(100vh - 180px);
    padding-top: 0px;

    @media (max-width: 1230px) {
      max-height: 40vh;
    }

    #image-map {
      max-height: calc(100vh - 180px);

      @media (max-width: 1230px) {
        max-height: 40vh;
      }
    }
  }
  .dashboard__map-wrapper {
    max-height: calc(100vh - 180px);

    @media (max-width: 1230px) {
      max-height: 40vh;
    }
  }

  @media (max-width: $sm) {
    .dashboard__fit-to-bounds {
      top: 78px;
    }
  }

  @media (max-width: 470px) {
    .dashboard__tag-slider {
      top: 138px;
    }
    .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
      top: auto;
    }
  }

  .delete-input {
    margin-top: 1.5vh;
  }

  .routes-tabs {
    .page-title {
      font-weight: bold;
    }

    @media (max-width: $md) {
      width: 100%;
      padding-top: 26px;
    }

    .el-tabs__header {
      margin: 15px 40px 0px;

      @media (max-width: $sm) {
        margin: 15px 19px 0px;
      }
    }

    .el-tabs--top .el-tabs__item.is-top {
      font-weight: bold;
      font-size: 16px;
    }

    .el-range-editor--small.el-input__inner {
      width: 300px;

      @media (max-width: $xs) {
        width: 70vw;
      }
    }
  }
}
</style>
