<template>
  <div class="anchor-card">
    <el-card>
      <div class="line" :style="{ backgroundColor: lineColor }"></div>
      <div class="content">
        <div class="content-head">
          <div class="title">
            <div
              v-if="anchor.type === 0"
              class="status-circle"
              :style="computeColor(anchor)"
            ></div>
            <span class="label">{{ anchor.label }}</span>
          </div>
          <span class="quantity"
            >{{ $t("labels.quantity") }}:
            {{ anchor.tagsList?.length ?? 0 }}</span
          >
        </div>
        <div class="content-table">
          <div v-if="!anchor.tagsList?.length" class="no-data">
            <span>{{ $t("labels.noData") }}</span>
          </div>
          <el-table
            v-else
            :data="anchor.tagsList"
            class="custom-table"
            :row-class-name="rowClassName"
            :height="windowWidth > 1350 ? '220px' : '130px'"
          >
            <template slot="empty">
              {{ $t("labels.noData") }}
            </template>
            <el-table-column
              prop="label"
              :label="$tc('labels.tag', 1)"
              align="center"
              min-width="81px"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.label">
                  {{ scope.row.label }}
                </template>
                <template v-else>
                  <span>{{ toHex(scope.row.tagId) }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="rssi" align="center" min-width="64px">
              <template slot="header">
                <el-tooltip
                  placement="top"
                  :content="$t('tooltip.rangeDescription')"
                  :hide-after="3000"
                >
                  <span>{{ $t("labels.range") }}</span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <template v-if="scope.row.rssi">
                  {{ thresholdToRange(scope.row.rssi) }}
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="lastSeenTs"
              :label="$t('labels.lastSeen')"
              align="center"
              min-width="85px"
            >
              <template slot-scope="scope">
                <template>
                  {{
                    scope.row.lastSeenTs
                      ? moment
                          .tz(
                            moment.unix(scope.row.lastSeenTs),
                            clientData.timezone
                          )
                          .format("LTS")
                      : ""
                  }}
                  <span
                    v-if="
                      scope.row.lastSeenTs &&
                        isDateBeforeToday(scope.row.lastSeenTs, clientData)
                    "
                  >
                    <el-tooltip placement="top">
                      <div slot="content">
                        {{
                          moment
                            .tz(
                              moment.unix(scope.row.lastSeenTs),
                              clientData.timezone
                            )
                            .format("LLL")
                        }}
                      </div>
                      <i class="el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import { isDateBeforeToday } from "@/utils/utils";

export default {
  name: "InventoryAnchorCard",

  props: {
    anchor: {
      type: Object,
      default: () => ({})
    },
    tagToHighlight: {
      type: String,
      default: ""
    },
    toHex: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      moment,
      isDateBeforeToday,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    lineColor() {
      return this.anchor.type === 0
        ? "#72a9cd"
        : this.anchor.type === 1
        ? "#1f4258"
        : "#1f2658";
    }
  },

  methods: {
    computeColor(anchor) {
      return `background-color: ${
        this.isAnchorOffline(anchor.offlineTs) ? "red" : "green"
      };`;
    },

    // Checks if the anchor is offline
    isAnchorOffline(offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        // if (!powerSource) {
        if (moment.utc(moment.unix(offlineTs)).isBefore(sixMinutesAgo)) {
          return true;
        }
        return false;
        // } else {
        //   const oneMinuteAgo = moment()
        //     .utc()
        //     .subtract(60, "seconds");

        //   if (moment.utc(moment.unix(offlineTs)).isBefore(oneMinuteAgo)) {
        //     return true;
        //   }
        // }
      }
      // return false;
    },

    // Checks if the tag matches the searched tag
    matchTagToHighlight(tag, searchedTag) {
      const hexTagId = this.toHex(tag.tagId);
      return (
        searchedTag === hexTagId ||
        searchedTag.slice(-3).includes(hexTagId.slice(-3)) ||
        tag.label.toUpperCase() === searchedTag
      );
    },

    // Highlights the row if tag if found on the table
    rowClassName({ row }) {
      if (this.tagToHighlight?.length >= 3)
        return this.matchTagToHighlight(row, this.tagToHighlight.toUpperCase())
          ? "highlight"
          : "";
      else return "";
    },
    thresholdToRange(tagRssi) {
      return tagRssi >= -16 ? 0 : -tagRssi - 16;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }
};
</script>

<style lang="scss">
.anchor-card {
  .el-card {
    width: 351px;
    height: 382px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e2e2e2;

    @media (max-width: $xs) {
      width: 85vw !important;
    }

    @media (max-width: 1500px) {
      width: 290px;
    }
    @media (max-width: 1350px) {
      height: 270px;
    }

    .el-card__body {
      padding: 0;
    }

    .line {
      width: 100%;
      height: 16px;
      background-color: $--color-primary;
    }

    .el-table__body-wrapper::-webkit-scrollbar {
      width: 7px;
    }

    .el-table__body-wrapper::-webkit-scrollbar-track {
      background: none;
    }

    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #a5b3bc;
      border-radius: 10px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 25px 50px;

      &-head {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        width: 100%;
        padding: 0 10px;

        & .title {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          text-align: start;

          & .label {
            font-size: 20px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          & .status-circle {
            width: 12px;
            height: 11px;
            border-radius: 100%;
          }
        }

        & .quantity {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: start;
        }
      }

      &-table {
        width: 100%;

        .el-icon-info {
          color: #e6a23c;
        }

        .el-table {
          border-collapse: separate;
          border-spacing: 0;
          width: 100%;

          &::before {
            content: none;
            border: none !important;
          }

          .el-table__header {
            border-bottom: 1px solid black;

            th {
              color: black;
            }
          }

          .el-table__body {
            td {
              border: none;
            }

            th {
              border: none;
            }
          }
        }

        & .no-data {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: #bfbfbf;
          font-weight: bold;
          padding: 40px 0;
        }

        & .custom-table {
          font-size: 14px;
          color: black;
          text-align: center;

          .highlight {
            background-color: #fff583 !important;
          }

          & .el-table__body {
            td,
            th {
              padding: 8px 0 !important;
            }
          }
          & .el-table__header {
            td,
            th {
              padding: 0 0 10px !important;
            }
          }
        }
      }
    }
  }
}
</style>
