<template>
  <div class="user-anchors">
    <div
      v-if="anchors.loading"
      v-loading="true"
      class="user-anchors__loading"
    />
    <div v-else-if="anchors.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <div class="anchor-full-table">
        <AnchorsTable
          :anchorsData="anchors.data"
          :firmwareVersions="firmwareVersions"
          :isAnchorOffline="isAnchorOffline"
          :getFirmwareVersion="getFirmwareVersion"
          :onOffClasses="onOffClasses"
          :computedColor="computedColor"
          @handle-edit="handleEdit"
        />
      </div>
      <div class="anchor-expandable-table">
        <ExpandableTable
          :anchorsData="anchors.data"
          :firmwareVersions="firmwareVersions"
          :isAnchorOffline="isAnchorOffline"
          :getFirmwareVersion="getFirmwareVersion"
          :onOffClasses="onOffClasses"
          :computedColor="computedColor"
          @handle-edit="handleEdit"
        />
      </div>
    </template>

    <div v-if="anchors.data.length" class="refresh">
      <el-button type="primary" @click="updateAnchors()">{{
        $t("button.refreshStatus")
      }}</el-button>
    </div>

    <!-- Edit anchors dialog -->
    <el-dialog
      v-if="anchorToEdit"
      :title="
        $t('dialogs.editGateway.title') +
          anchorToEdit.anchorId
            .toString(16)
            .padStart(12, '0')
            .toUpperCase()
            .match(/.{1,2}/g)
            .join(':')
      "
      :visible.sync="editAnchorDialog.visible"
      :close-on-click-modal="false"
      @close="$refs.anchorForm.clearValidate()"
      :custom-class="
        isAzitek ? 'el-dialog__is-azitek' : 'el-dialog__is-not-azitek'
      "
    >
      <!--Somethin wrong with the class binding here--->
      <el-form
        label-position="left"
        :model="anchorForm"
        ref="anchorForm"
        label-width="120px"
        :class="isAzitek ? 'anchors_form' : ''"
        hide-required-asterisk
      >
        <div>
          <el-form-item :label="$t('dialogs.editGateway.label')" prop="label">
            <el-input
              type="text"
              v-model="anchorForm.label"
              autocomplete="off"
            />
            <div
              v-if="anchorForm.error.length"
              class="el-form-item__error el-form-item__error--server"
            >
              {{ anchorForm.error }}
            </div>
          </el-form-item>
          <div class="ip-configs">
            <el-form-item
              :label="$t('dialogs.editGateway.staticIp')"
              prop="staticIp"
            >
              <el-input
                type="text"
                v-model="anchorForm.staticIp"
                autocomplete="off"
                @change="editIP = true"
              />
              <div
                v-if="anchorForm.error.length"
                class="el-form-item__error el-form-item__error--server"
              >
                {{ anchorForm.error }}
              </div>
            </el-form-item>

            <el-form-item :label="$tc('labels.gateway', 1)" prop="gateway">
              <el-input
                type="text"
                v-model="anchorForm.gateway"
                autocomplete="off"
                @change="editIP = true"
              />
              <div
                v-if="anchorForm.error.length"
                class="el-form-item__error el-form-item__error--server"
              >
                {{ anchorForm.error }}
              </div>
            </el-form-item>

            <el-form-item
              :label="$t('dialogs.editGateway.subnet')"
              prop="subnet"
            >
              <el-input
                type="text"
                v-model="anchorForm.subnet"
                autocomplete="off"
                @change="editIP = true"
              />
              <div
                v-if="anchorForm.error.length"
                class="el-form-item__error el-form-item__error--server"
              >
                {{ anchorForm.error }}
              </div>
            </el-form-item>

            <el-form-item
              :label="$t('dialogs.editGateway.primaryDns')"
              prop="dnsPrimary"
            >
              <el-input
                type="text"
                v-model="anchorForm.dnsPrimary"
                autocomplete="off"
                @change="editIP = true"
              />
              <div
                v-if="anchorForm.error.length"
                class="el-form-item__error el-form-item__error--server"
              >
                {{ anchorForm.error }}
              </div>
            </el-form-item>

            <el-form-item
              :label="$t('dialogs.editGateway.secondaryDns')"
              prop="dnsSecondary"
            >
              <el-input
                type="text"
                v-model="anchorForm.dnsSecondary"
                autocomplete="off"
                @change="editIP = true"
              />
              <div
                v-if="anchorForm.error.length"
                class="el-form-item__error el-form-item__error--server"
              >
                {{ anchorForm.error }}
              </div>
            </el-form-item>
          </div>
          <el-form-item :label="$t('labels.buzzer')">
            <div class="buzzer-timeout">
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">
                  {{ $t("tooltip.enableDescription") }}<br />{{
                    $t("tooltip.disableDescription")
                  }}
                </div>
                <el-checkbox
                  v-model="anchorForm.buzzerDisable"
                  size="medium"
                  border
                  >{{ $t("actions.disable") }}</el-checkbox
                >
              </el-tooltip>
            </div>
          </el-form-item>
        </div>
        <div class="azitek-settings" v-if="isAzitek">
          <el-form-item
            :label="$t('dialogs.editGateway.fwVersion')"
            prop="staticIp"
          >
            <el-select
              v-model="anchorForm.fwVersion"
              :placeholder="$t('placeholder.selectFirmwareVersion')"
              filterable
              size="medium"
            >
              <el-option
                v-for="fw in firmwareVersions"
                :key="fw.id"
                :label="fw.version"
                :value="fw.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('dialogs.editGateway.setup')" prop="gateway">
            <el-checkbox v-model="anchorForm.setup" size="medium" border>{{
              $t("actions.enabled")
            }}</el-checkbox>
          </el-form-item>

          <el-form-item :label="$t('labels.ssid')" prop="staticIp">
            <el-checkbox v-model="anchorForm.ssid" size="medium" border>{{
              $t("actions.enabled")
            }}</el-checkbox>
          </el-form-item>

          <el-form-item
            :label="$t('dialogs.editGateway.options')"
            prop="gateway"
          >
            <el-checkbox v-model="anchorForm.options" size="medium" border>{{
              $t("actions.enabled")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item
            :label="$t('labels.gatewayRange')"
            v-if="isClientIM || isClientPM || isClientCC || isClientAM"
          >
            <el-slider
              v-model="anchorForm.magRssiThreshold"
              :marks="marksNonDefault"
              :step="1"
              :min="0"
              :max="16"
            >
            </el-slider>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="editAnchorDialog.visible = false"
          :disabled="editAnchorDialog.loading"
          >{{ $t("button.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="updateAnchorHandler"
          :loading="editAnchorDialog.loading"
          >{{ $t("button.save") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types.ts";
import { mapState, mapActions, mapGetters } from "vuex";
import AnchorsTable from "@/components/user-settings/components/UserAnchorsTable.vue";
import ExpandableTable from "@/components/user-settings/components/UserAnchorsExpandableTable.vue";
import anchorsApi from "@/modules/anchors/api/anchors.api";
import moment from "moment-timezone";

export default {
  components: {
    AnchorsTable,
    ExpandableTable
  },

  props: {
    showOperations: {
      type: Boolean,
      default: true
    },
    showActiveRoute: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      clientData: "data"
    }),
    ...mapGetters("auth", ["isAdmin", "isAzitek"]),
    ...mapGetters("clients", [
      "isClientIM",
      "isClientPM",
      "isClientCC",
      "isClientAM"
    ])
  },

  data() {
    return {
      editIP: false,
      moment,
      anchorForm: {
        label: "",
        staticIp: "",
        gateway: "",
        subnet: "",
        dnsPrimary: "",
        dnsSecondary: "",
        error: "",
        buzzerDisable: "",
        fwVersion: "",
        options: "",
        setup: "",
        ssid: "",
        magRssiThreshold: 0
      },
      rules: {
        label: [
          {
            required: true,
            message: this.$t("messages.labelRequired"),
            trigger: "blur"
          },
          {
            max: 45,
            message: this.$t("messages.labelMaxLength"),
            trigger: "blur"
          }
        ]
      },
      anchorToEdit: null,
      editAnchorDialog: {
        visible: false,
        loading: false
      },
      firmwareVersions: [],
      marksNonDefault: {
        0: this.$t("labels.min"),
        16: this.$t("labels.max")
      }
    };
  },

  watch: {
    editIP(newValue) {
      if (newValue) this.editAnchorSettings();
    },
    "$route.params.tab": {
      deep: true,
      handler(newValue) {
        if (newValue === "anchors") this.updateAnchors();
      }
    },
    "$i18n.locale"() {
      this.marksNonDefault = {
        0: this.$t("labels.min"),
        16: this.$t("labels.max")
      };
      this.rules = {
        label: [
          {
            required: true,
            message: this.$t("messages.labelRequired"),
            trigger: "blur"
          },
          {
            max: 45,
            message: this.$t("messages.labelMaxLength"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions("clients", {
      updateAnchor: actionTypes.CLIENTS_UPDATE_ANCHOR,
      updateAnchorInfo: actionTypes.CLIENT_UPDATE_ANCHOR_INFO
    }),

    thresholdToRange(magRssiThreshold) {
      return magRssiThreshold >= -16 ? 0 : -magRssiThreshold - 16;
    },

    handleEdit(index, row) {
      this.anchorForm.label = row.label;
      this.anchorForm.staticIp = row.staticIp;
      this.anchorForm.gateway = row.gateway;
      this.anchorForm.subnet = row.subnet;
      this.anchorForm.dnsPrimary = row.dnsPrimary;
      this.anchorForm.dnsSecondary = row.dnsSecondary;
      this.anchorForm.buzzerDisable = row.buzzerDisable;
      this.anchorForm.fwVersion = row.firmwareId;
      this.anchorForm.setup = row.setup;
      this.anchorForm.ssid = row.updateSsids;
      this.anchorForm.options = row.updateWifiOptions;
      this.anchorToEdit = row;
      this.anchorForm.magRssiThreshold = this.thresholdToRange(
        row.magRssiThreshold
      );
      this.editAnchorDialog.visible = true;
    },

    onAnchorClick(data) {
      this.$emit("row-click", data);
    },

    getFirmwareVersion(firmwareId) {
      const fw = this.firmwareVersions.find(fw => fw.id == firmwareId);
      if (fw) return fw.version;
      else return "N/A";
    },

    computedColor(anchor) {
      if (
        this.isAnchorOffline(
          anchor.lastHeartbeat?.powerSource ?? null,
          anchor.offlineTs ?? null
        )
      )
        return "red";
      else return "#606266";
    },

    async updateAnchorHandler() {
      this.$refs.anchorForm.validate(async valid => {
        if (valid) {
          try {
            this.editAnchorDialog.loading = true;
            let magRssiThreshold;

            if (this.anchorForm.threshold == 0) {
              magRssiThreshold = -16;
            } else {
              magRssiThreshold = -32 + (16 - this.anchorForm.magRssiThreshold);
            }

            const payload = {
              id: this.anchorToEdit.anchorId,
              label: this.anchorForm.label,
              staticIp: this.anchorForm.staticIp,
              gateway: this.anchorForm.gateway,
              subnet: this.anchorForm.subnet,
              dnsPrimary: this.anchorForm.dnsPrimary,
              dnsSecondary: this.anchorForm.dnsSecondary,
              buzzerDisable: this.anchorForm.buzzerDisable,
              firmwareId: this.anchorForm.fwVersion,
              setup: this.anchorForm.setup,
              magRssiThreshold: magRssiThreshold
            };

            if (this.isAzitek) {
              payload.updateSsids = this.anchorForm.ssid;
              payload.updateWifiOptions = this.anchorForm.options;
            }
            await this.updateAnchor(payload);
            this.$notify({
              title: this.$t("statusMessages.success"),
              message: this.$t("messages.gatewayUpdated"),
              type: "success"
            });
            // this.anchors.data.forEach(anchor => {
            //   if (anchor.anchorId === this.anchorToEdit.anchorId) {
            //     anchor.label = this.anchorForm.label,
            //     anchor.staticIp = this.anchorForm.staticIp,
            //     anchor.gateway = this.anchorForm.gateway,
            //     anchor.subnet = this.anchorForm.subnet,
            //     anchor.dnsPrimary = this.anchorForm.dnsPrimary,
            //     anchor.dnsSecondary = this.anchorForm.dnsSecondary,
            //     anchor.buzzerDisable = this.anchorForm.buzzerDisable;
            //     anchor.firmwareId = this.anchorForm.fwVersion;
            //     anchor.setup = this.anchorForm.setup;
            //     anchor.magRssiThreshold = magRssiThreshold;
            //     anchor.updateSsids = this.anchorForm.ssid;
            //     anchor.updateWifiOptions = this.anchorForm.options;
            //   }
            // });
          } catch (error) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.genericError")
            });
          } finally {
            this.editAnchorDialog.loading = false;
            this.editAnchorDialog.visible = false;

            this.anchorToEdit = null;
          }
        }
      });
    },

    editAnchorSettings() {
      const validateNewIP = (rule, value, callback) => {
        const regex = new RegExp(
          "^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\\.(?!$)|$)){4}$"
        );

        if (!regex.test(value) && value !== "") {
          callback(this.$t("messages.validIpAddress"));
        }

        if (
          value === "" &&
          (this.anchorForm.gateway !== "" ||
            this.anchorForm.subnet !== "" ||
            this.anchorForm.staticIp !== "" ||
            this.anchorForm.dnsPrimary !== "" ||
            this.anchorForm.dnsSecondary !== "")
        ) {
          callback(this.$t("messages.fillThisValue"));
        }

        callback();
      };
      this.rules = {
        ...this.rules,
        staticIp: [{ validator: validateNewIP, trigger: "blur" }],
        gateway: [{ validator: validateNewIP, trigger: "blur" }],
        subnet: [{ validator: validateNewIP, trigger: "blur" }],
        dnsPrimary: [{ validator: validateNewIP, trigger: "blur" }],
        dnsSecondary: [{ validator: validateNewIP, trigger: "blur" }]
      };
    },

    onOffClasses(baseClass, value) {
      return `user-anchors__${baseClass} user-anchors__${baseClass}--${
        value ? "on" : "off"
      }`;
    },

    isAnchorConnected(power, createdDate) {
      const oneMinuteAgo = moment()
        .utc()
        .subtract(60, "seconds");
      if (power) return !moment.unix(createdDate).isAfter(oneMinuteAgo);
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(360, "seconds");
        return !moment.unix(createdDate).isAfter(sixMinutesAgo);
      }
    },

    isAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource) {
          if (moment.utc(moment.unix(offlineTs)).isBefore(sixMinutesAgo)) {
            return true;
          }
          return false;
        } else {
          const oneMinuteAgo = moment()
            .utc()
            .subtract(60, "seconds");

          if (moment.utc(moment.unix(offlineTs)).isBefore(oneMinuteAgo)) {
            return true;
          }
        }
      }
      return false;
    },

    async updateAnchors() {
      if (this.anchors.data)
        try {
          const { data } = await anchorsApi.getRefreshStatus();
          let payload;
          this.anchors.data.forEach(anchor => {
            data.forEach(dataElement => {
              if (dataElement.anchorId === anchor.anchorId) {
                payload = anchor;
                payload.rssi = dataElement.rssi;
                payload.lastHeartbeat.powerSource = dataElement.powerSource;
                payload.lastHeartbeat.createdDate = dataElement.createdDate;
                payload.lastHeartbeat.ssidName = dataElement.ssidName;
                payload.lastHeartbeat.ssid = dataElement.ssid;
                payload.offlineTs = dataElement.offlineTs;

                this.updateAnchorInfo(payload);
              }
            });
          });
        } catch (_) {
          // DO nothing
        }
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  async created() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.isLoading = true;
    //const _self = this; // eslint-disable-line

    /*this.interval = setInterval(this.updateAnchors, 10000);

    this.currentTime = moment().format("HH:mm");
    this.currentTimeInterval = setInterval(
      function(_self) {
        _self.currentTime = moment().format("HH:mm");
      },
      60000,
      this
    );`*/
    if (this.isAzitek) {
      try {
        const res = await anchorsApi.getFirmwareVersions();
        if (res.status == 200) this.firmwareVersions = res.data;
      } catch (err) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: err.response.data.detail
        });
      }
    }
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.user-anchors {
  padding: 24px 0;
  justify-content: center;

  .el-dialog {
    &__is-azitek {
      width: 840px;

      @media (max-width: 1080px) {
        width: 400px;
      }
    }

    &__is-not-azitek {
      max-width: 420px;
    }
  }

  .anchors_form {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .dialog-footer {
    margin-top: -5%;
  }

  .refresh {
    margin-top: 2%;

    @media (max-width: 1080px) {
      margin-top: 7%;
    }
  }

  .ip-configs {
    border-top: 1px solid black;
    padding-top: 5%;
    border-bottom: 1px solid black;
    margin-bottom: 5%;
  }

  .el-form .el-form-item label.el-form-item__label {
    line-height: 40px;
    word-break: break-word;
  }

  .el-slider {
    position: absolute;
    left: 10px;
    width: 80%;

    &__marks-text {
      width: 50px;
      color: #2c3e50;
    }
  }

  .buzzer-timeout {
    display: flex;
    margin-top: 2px;
  }

  .cell span {
    display: block;
    text-align: center;

    i {
      font-size: 24px;
    }
  }

  .cell .edit-buttons {
    display: flex;
    justify-content: center;
    i {
      font-size: 15px;
    }
  }

  &__power-source--on {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACy0lEQVRIS62VT0hUURTGv3NfERqtJPpDkKkE6ZgzU9Amg2rTSpzpD6YWtIiiTMfEsNoWkYTOTC1q0SIq1LSZqaBFhQsXUkSNWUZQYSU1gumi1Epn7on3hhnfPN/om+zu3jvnfL/vnXvufYQ51qagP5+ZK2LAdgguAnNOPF18B+MtCe6WQmnrLzsxmE6GzAKbu1rypklpFpBuCGGak6yTkklRuiTHTvW56j8Z9WYVO0Leg5LpqgCy5vo6Y0xKOakIOvLS5bmlj6UAHAFvE4guZCJszGVCY1953aXE+yRAdQ6mGwsRT9QyU3Wfu/a2+qwBtJ4ryptM25LWjMQEoBSFd9d81gCOgK8ThD3/w/2MBnWEXbUVpI6ilLH3805LGrrq0JVrw8Ohd/gdi85kSckskEfOgO8sE879i3tVvKF4Gyrz7Xg2MoT6pw9SIcynqSTgeyIIOzMF6MXV2onoFA71dOLDj1G91GMqCXkjgmmlEZCzJBujfyZNuWbix3pDeD02nJrP+EolwdYpAbFYH6nKd+Dohi2o6b2HV2ORlCLL4vGqqVkAR85qXC+ND9RkdBrHe0NJiCp+srgUqoFEW0ydz1hSAb5vAlilP3ke21YcKHCmQPrHIqi3laK6wLI4oLXIZJNVp0ZIz/Agdq1Zb9W5lkeQj8ge8p4hpvPG3TRCEnF1WuZpi+6sURNtvH95nTId/Wh20IyQjMQBCankaVeFM+S/w8x7zWYyAXHn2qw714S4LezyVGoAe7A1lyUGhBDZ6SArspZh+NdPi+dRji8iWfi8vGEoeV07g95qBt20qDB3GmN/2F3XHt9o3bIH/I1E3LwgCKMh7K5r0Y99ip494K8i5msQWJoZSI6DxeGE87QANeC4e2UthLwIGdtn4RqXAHcIBU0vyjxfzMY9rVF184mpAkQ7wCgEYXl8QDBCJAeYlG7ERLv650on8hf6Czzo4QTILgAAAABJRU5ErkJggg==");

      @media (max-width: 1080px) {
        width: 17px;
        height: 17px;
        background-size: 17px;
      }
    }
  }

  &__power-source--off {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAC9UlEQVRIS62Wy08TURTGv3unmKjLtgkaE23ZwRJ6qbhSN7SUR4waVOICIv4BBlNeLRSN0RjXsoDEqBHUNU/DTmCmshM3hvpAYSF1hcagc6+5E2YyHWcGEWfXOWe+37nndUvg87yqrq7QFaWVAycBVAEISncBbBDgDQXmADyp1bR3XjLEzZCvqYkKQu7ohJyhgKuP+R0HBAWec0qvH19cfO/U++NjlbHL4Pw+KN3vdzqnjQPfFSGusnz+kd1WAtBisbQg5NZuhJ2+gpCuuKreNd9bACNy4MFexC1RIdpYPv9Y/jYAMucceG1Py+GODmytr2NjYsKXGW5pQVkwiLWREcuPA994IFB1Yn7+gwFQGXsG4KzpIcWPdHYCnKMwNOQJCTc3I9LdDRCCT8PDWBsdtSCCkPG4qrYS2Yo/FeWtvVtCySSi/f0ApZ6QcGMjIr29hrjgHCuZDL7OztpPIUBplKiM9QK44cxDqKEB0b4+V0g4lUJE2jzEbbXoJguMvaDAabdEh1IpRGWUtpNIURMsIy9kMijaIi9pUSFmJWCdAuVelTSitUEMP0qNtBSyWRRnZrybQNc/S8AWBcr8WsWeb2NVSPGBARSnp307jANb/w4YHERxampnwDxjawpwyDNFTU2I9PRYBTWGx0zRTpDtFHkW2d7nZlpkkSuyWavwK7kcipOT7vFxPiPbtAfATaeHnNBIOm1Fbi9osL6+BFLI5bDhDklLQIQDK/ZB8xM3AwkmEqjIZDyHkcsRDQSi5qp4CuCcc1Xs1OehRALRbYhzVWxfRBcNwEI8fgycL1PggAVpb8eP1dWS8XcdxmQS+8rLS/YQgE0iRCXL51etda3FYm2CkIf/Y10LQi7EVXXMWtemqMpYF4A7e4Rcq9W0e9Y+coppsdglnZBhChzcJWhTEHLFjNwTIA0v6+qOKrp+Wwhx/i8ufU6BcUXX0zVLSx+dQfn+Y5DFV3S9FcApwXklV5Swset0/QsIWQalc78CgTF5c3md9jeDjGiF9sPCPQAAAABJRU5ErkJggg==");

      @media (max-width: 1080px) {
        width: 17px;
        height: 17px;
        background-size: 17px;
      }
    }
  }
}
</style>
