import { HttpRequest } from "@/services/api/http";

export default new (class PositionLogsApi extends HttpRequest {
  constructor() {
    super();
  }

  public async createTags(tags: any) {
    return this.axiosClient.put(`/position_logs/tags`, tags);
  }

  public async updateTagPosition(tagId: number, tagInfo: any) {
    return this.axiosClient.put(`/position_logs/tag/${tagId}`, tagInfo);
  }

  public async getAnalyticsLogs(
    timestampStart: number,
    timestampEnd: number,
    shiftStart: number,
    shiftEnd: number,
    anchorIds?: Array<number>,
    routeInstanceId?: number,
    page?: number,
    pageSize?: number
  ) {
    const anchorIdsQuery =
      anchorIds && anchorIds.length > 0
        ? "?" + anchorIds.map(id => `anchor_ids=${id}`).join("&")
        : "";

    return this.axiosClient.get(
      `/position_logs/analytics/${timestampStart}/${timestampEnd}/${shiftStart}/${shiftEnd}${anchorIdsQuery}${"&page=" +
        page}${pageSize ? "&page_size=" + pageSize : ""}${
        routeInstanceId ? "&route_instance_id=" + routeInstanceId : ""
      }`
    );
  }

  public async getTripFrequency(
    timestampStart: number,
    timestampEnd: number,
    anchorIds?: Array<number>,
    routeInstanceId?: number
  ) {
    const anchorIdsQuery =
      anchorIds && anchorIds.length > 0
        ? "?" + anchorIds.map(id => `anchor_ids=${id}`).join("&")
        : "";

    return this.axiosClient.get(
      `/position_logs/trip_frequency/${timestampStart}/${timestampEnd}${anchorIdsQuery}${
        routeInstanceId ? `&route_instance_id=${routeInstanceId}` : ""
      }`
    );
  }

  public async getAnchorPositionLogsFromTo(
    startTime: number,
    endTime: number,
    anchorIds?: Array<number>,
    startShift?: number,
    endShift?: number,
    routeInstanceId?: number
  ) {
    const anchorIdsQuery =
      anchorIds && anchorIds.length > 0
        ? "?" + anchorIds.map(id => `anchor_ids=${id}`).join("&")
        : "";

    return this.axiosClient.get(
      `/position_logs/histogram_values/${startTime}/${endTime}${
        startShift ? `/${startShift}` : ""
      }${endShift ? `/${endShift}` : ""}${anchorIdsQuery}${
        routeInstanceId ? `&route_instance_id=${routeInstanceId}` : ""
      }
      `
    );
  }
})();
