<template>
  <el-table
    class="full-table"
    @row-click="data => $emit('row-click', data)"
    :data="anchorsData"
    style="width: 100%"
    border
  >
    <template slot="empty">
      {{ $t("labels.noData") }}
    </template>
    <el-table-column :resizable="false" :label="$t('labels.idDecimal')">
      <template slot-scope="scope"> {{ scope.row.anchorId }} </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.label')">
      <template slot-scope="scope"> {{ scope.row.label }} </template>
    </el-table-column>
    <el-table-column
      :resizable="false"
      :label="$t('labels.travelRate')"
      class="travel-rate"
      v-if="isClientDefault"
    >
      <template slot-scope="scope">
        {{ $t("labels.today") }}: {{ getTravelRate(scope.row.anchorId, "today")
        }}<br />
        {{ $t("labels.twoWeeks") }}:
        {{ getTravelRate(scope.row.anchorId, "last-two-weeks") }}
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.macAddress')">
      <template slot-scope="scope"
        >{{
          scope.row.anchorId
            .toString(16)
            .padStart(12, "0")
            .toUpperCase()
            .match(/.{1,2}/g)
            .join(":")
        }}
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.signal')" width="70">
      <template slot-scope="scope">
        <WifiIcon
          :signal="scope.row.rssi"
          :noConnection="
            scope.row.lastHeartbeat
              ? isAnchorOffline(
                  scope.row.lastHeartbeat.powerSource,
                  scope.row.offlineTs
                )
              : true
          "
        />
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.bssid')">
      <template slot-scope="scope"
        >{{ scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssid : "" }}
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.ssidName')">
      <template slot-scope="scope"
        >{{
          scope.row.lastHeartbeat
            ? scope.row.lastHeartbeat.ssidName
              ? scope.row.lastHeartbeat.ssidName
              : $t("labels.na")
            : $t("labels.na")
        }}
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.fwVersion')">
      <template slot-scope="scope" v-if="firmwareVersions"
        >{{ getFirmwareVersion(scope.row.firmwareId) }}
      </template>
    </el-table-column>
    <el-table-column :resizable="false" :label="$t('labels.power')" width="90">
      <template slot-scope="scope">
        <div
          :class="
            onOffClasses(
              'power-source',
              scope.row.lastHeartbeat ? scope.row.lastHeartbeat.powerSource : ''
            )
          "
        >
          <img src="@/assets/power_socket.svg" :alt="$t('alt.powerSource')" />
        </div>
      </template>
    </el-table-column>

    <el-table-column
      :resizable="false"
      :label="$t('labels.lastSeen')"
      width="150px"
    >
      <template slot-scope="scope" v-if="scope.row.lastHeartbeat">
        <span v-bind:style="{ color: computedColor(scope.row) }">
          {{
            moment
              .tz(
                moment.unix(scope.row.lastHeartbeat.createdDate),
                clientData.timezone
              )
              .format("HH:mm, DD/MM/YYYY")
          }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      :resizable="false"
      v-if="isAdmin"
      :label="$t('labels.operations')"
      width="110"
    >
      <template slot-scope="scope">
        <div class="edit-buttons">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            ><i class="el-icon-edit"></i
          ></el-button>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import WifiIcon from "@/components/icons/WifiIcon.vue";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    WifiIcon
  },
  props: {
    anchorsData: {
      type: Array
    },
    firmwareVersions: {
      type: Array
    },
    getFirmwareVersion: {
      type: Function
    },
    onOffClasses: {
      type: Function
    },
    computedColor: {
      type: Function
    },
    isAnchorOffline: {
      type: Function
    }
  },
  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapGetters("auth", ["isAdmin", "isAzitek"]),
    ...mapGetters("clients", ["isClientDefault"])
  },
  data() {
    return {
      moment
    };
  },
  methods: {
    handleEdit(index, row) {
      this.$emit("handle-edit", index, row);
    },
    getTravelRate(anchorId, date) {
      let usageInfo;
      this.resources.usageInfo.forEach(value => {
        if (anchorId === value.anchorId) usageInfo = value;
      });
      if (date == "today") {
        return usageInfo.dUsage ? usageInfo.dUsage + "%" : "-";
      } else {
        return usageInfo.twUsage ? usageInfo.twUsage + "%" : "-";
      }
    }
  }
};
</script>

<style lang="scss">
.el-table.full-table {
  @media (max-width: 1080px) {
    display: none;
  }
  td.el-table__cell div {
    word-break: auto-phrase;
  }
}
</style>
