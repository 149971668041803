<template>
  <div class="user-users-list">
    <div v-if="users.loading" v-loading="true" class="user-users__loading" />
    <div v-else-if="users.error">
      {{ $t("messages.errorOccurred") }}
    </div>
    <template v-else>
      <div v-if="!users.data.length">
        {{ $t("messages.noUsers") }}
      </div>
      <div v-else class="user-users-list__container">
        <el-table border :data="users.data">
          <template slot="empty">
            {{ $t("labels.noData") }}
          </template>
          <el-table-column :resizable="false" :label="$t('labels.label')">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column :resizable="false" :label="$t('formFields.email')">
            <template slot-scope="scope">
              {{ scope.row.email }}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            :label="$t('formFields.admin')"
            width="80"
          >
            <template slot-scope="scope">
              {{
                scope.row.authScopes.includes("client")
                  ? $t("confirmation.yes")
                  : $t("confirmation.no")
              }}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            :label="$t('formFields.lastLogin')"
            width="160"
            v-if="isAzitek"
          >
            <template slot-scope="scope">
              {{
                scope.row.lastLoginTs
                  ? moment
                      .tz(
                        moment.unix(scope.row.lastLoginTs),
                        clientData.timezone
                      )
                      .format("HH:mm, DD/MM/YYYY")
                  : $t("labels.na")
              }}
            </template>
          </el-table-column>
          <el-table-column
            :resizable="false"
            :label="$t('labels.operations')"
            width="110"
          >
            <template slot-scope="scope">
              <div class="column-btn">
                <el-button
                  v-if="user.data.id !== scope.row.id"
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)"
                  ><i class="el-icon-edit"></i>
                </el-button>
                <el-button
                  v-if="user.data.id !== scope.row.id"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  ><i class="el-icon-delete"></i
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-button
        class="user-users-list__new"
        type="primary"
        @click="newUserDialog.visible = true"
        >{{ $t("button.newUser") }}
      </el-button>

      <!-- Delete user dialog -->
      <el-dialog
        v-if="userToDelete"
        :title="$t('dialogs.deleteUser.title')"
        :visible.sync="deleteUserDialog.visible"
        width="300px"
      >
        <span
          >{{ $t("dialogs.deleteUser.line1") }} "{{ userToDelete.name }}"?</span
        >
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="deleteUserDialog.visible = false"
            :disabled="deleteUserDialog.loading"
            >{{ $t("button.cancel") }}</el-button
          >
          <el-button
            type="danger"
            @click="deleteUserHandler"
            :loading="deleteUserDialog.loading"
            >{{ $t("button.delete") }}</el-button
          >
        </span>
      </el-dialog>

      <!-- create user dialog -->
      <el-dialog
        :title="$t('dialogs.createUser.title')"
        :visible.sync="newUserDialog.visible"
        class="user-users-list__new-dialog"
        :close-on-click-modal="false"
        width="420px"
      >
        <el-form
          label-position="top"
          :model="newUserForm"
          :rules="rules"
          ref="newUserForm"
          label-width="120px"
          hide-required-asterisk
        >
          <el-form-item :label="$t('formFields.name')" prop="name">
            <el-input type="text" v-model="newUserForm.name" autocomplete="off">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('formFields.email')" prop="email">
            <el-input
              type="text"
              v-model="newUserForm.email"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item v-if="isAdmin">
            <div>
              <el-checkbox size="medium" border v-model="newUserForm.client">
                <b>{{ $t("dialogs.createUser.manageDashboard") }}</b>
              </el-checkbox>
            </div>
          </el-form-item>
          <el-form-item v-if="isAdmin" prop="notifications" class="last-item">
            <div class="client-checkbox">
              <el-checkbox
                size="medium"
                border
                v-model="newUserForm.notifications"
              >
                <b>{{ $t("dialogs.createUser.emailNotifications") }}</b>
              </el-checkbox>
            </div>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="newUserDialog.visible = false">{{
            $t("button.cancel")
          }}</el-button>
          <el-button type="primary" @click="newUserHandler">{{
            $t("button.create")
          }}</el-button>
        </span>
      </el-dialog>

      <!-- edit user dialog -->
      <el-dialog
        :title="$t('dialogs.editUser.title')"
        :visible.sync="editUserDialog.visible"
        class="user-users-list__new-dialog"
        :close-on-click-modal="false"
      >
        <el-form
          label-position="right"
          :model="editUserForm"
          :rules="rules"
          ref="editUserForm"
          hide-required-asterisk
          class="user-form"
        >
          <el-form-item
            :label="$t('formFields.name')"
            prop="name"
            class="edit-user-name"
          >
            <el-input
              type="text"
              v-model="editUserForm.name"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('formFields.email')"
            prop="email"
            class="edit-user-name"
          >
            <el-input
              type="text"
              v-model="editUserForm.email"
              autocomplete="off"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="isAdmin"
            :label="$t('dialogs.editUser.manageDashboard')"
            class="edit-user-checkbox"
          >
            <el-checkbox size="medium" v-model="editUserForm.client">
              {{ $t("actions.enable") }}
            </el-checkbox>
          </el-form-item>

          <el-form-item
            v-if="isAdmin"
            prop="notifications"
            :label="$t('dialogs.editUser.emailNotifications')"
            class="edit-user-checkbox"
          >
            <el-checkbox size="medium" v-model="editUserForm.notifications">
              {{ $t("actions.enable") }}
            </el-checkbox>
          </el-form-item>

          <el-form-item
            v-if="isSuperUser"
            prop="superUser"
            :label="$t('dialogs.editUser.globalAccount')"
            class="edit-user-checkbox"
          >
            <el-checkbox size="medium" v-model="editUserForm.isSuperUser">
              {{ $t("actions.enable") }}
            </el-checkbox>
          </el-form-item>
          <el-select
            v-if="editUserForm.isSuperUser"
            v-model="superUsers"
            multiple
            placeholder="Select Client Accounts"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form>

        <span slot="footer" class="dialog-footer" id="new-user-footer">
          <el-button @click="editUserDialog.visible = false">{{
            $t("button.cancel")
          }}</el-button>
          <el-button type="primary" @click="editUser">{{
            $t("button.save")
          }}</el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types.ts";
import { mapGetters, mapState, mapActions } from "vuex";
import userApi from "@/modules/user/api/user.api";
import clientApi from "@/modules/clients/api/clients.api";
import moment from "moment-timezone";

export default {
  computed: {
    ...mapGetters("auth", ["isAdmin", "isSuperUser", "isAzitek"]),
    ...mapState("clients", {
      users: "users",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    })
  },

  data() {
    const validateNewPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("messages.provideAPassword")));
      }
      callback();
    };
    const validateNewPasswordConfirmation = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("messages.confirmPassword")));
      } else if (value !== this.newUserForm.password) {
        callback(new Error(this.$t("messages.passwordsDoNotMatch")));
      } else {
        callback();
      }
    };

    const validateNotifications = (rule, value, callback) => {
      const count = this.users.data.reduce(
        (a, c) =>
          c.notifications === true && c.id != this.editUserForm.id ? ++a : a,
        0
      );
      if (count === 0 && !value) {
        callback(new Error(this.$t("messages.needAtLeastOneUser")));
      }
      callback();
    };

    return {
      moment,
      revealPassword: false,
      revealConfirmPassword: false,
      newUserForm: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        error: "",
        client: false,
        notifications: false
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("messages.nameRequired"),
            trigger: "blur"
          }
        ],
        email: [
          {
            required: true,
            message: this.$t("messages.emailRequired"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("messages.validEmail"),
            trigger: "blur"
          }
        ],
        password: [{ validator: validateNewPassword, trigger: "blur" }],
        confirmPassword: [
          { validator: validateNewPasswordConfirmation, trigger: "blur" }
        ],
        notifications: [{ validator: validateNotifications, trigger: "blur" }]
      },
      newUserDialog: {
        visible: false,
        loading: false
      },
      editUserDialog: {
        visible: false,
        loading: false
      },
      editUserForm: {
        index: 0,
        id: 0,
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        error: "",
        client: false,
        notifications: false,
        isSuperUser: false
      },
      userToEdit: null,
      userToDelete: null,
      deleteUserDialog: {
        visible: false,
        loading: false
      },
      superUsers: [],
      options: []
    };
  },

  methods: {
    ...mapActions("clients", {
      createUser: actionTypes.CLIENTS_CREATE_USER,
      deleteUser: actionTypes.CLIENTS_DELETE_USER
    }),
    handleDelete(index, row) {
      this.userToDelete = row;
      this.deleteUserDialog.visible = true;
    },
    async handleEdit(index, row) {
      console.log(row);
      this.userToEdit = row;
      this.editUserForm.index = index;
      this.editUserDialog.visible = true;
      this.editUserForm.id = row.id;
      this.editUserForm.name = row.name;
      this.editUserForm.email = row.email;
      this.editUserForm.notifications = row.notifications;
      this.editUserForm.client = row.authScopes.includes("client")
        ? true
        : false;
      this.editUserForm.isSuperUser = row.authScopes.includes("super")
        ? true
        : false;
      console.log(row);
      this.superUsers = row.superUsers ? row.superUsers.map(x => x.id) : [];
      if (this.isAzitek) {
        const res = await clientApi.getAzitekClients();
        if (res.status == 200) this.options = res.data;
      } else {
        this.options = this.user.data.superUsers;
      }
    },

    editUser() {
      this.$refs.editUserForm.validate(async valid => {
        const authScopes = this.userToEdit.authScopes;
        console.log(authScopes);
        console.log(authScopes.includes("client"));
        if (this.editUserForm.client) {
          if (!authScopes.includes("client")) authScopes.push("client");
        } else authScopes.splice(authScopes.indexOf("client"), 1);

        if (this.editUserForm.isSuperUser) {
          if (!authScopes.includes("super")) authScopes.push("super");
        } else authScopes.splice(authScopes.indexOf("super"), 1);

        if (valid) {
          try {
            this.editUserDialog.loading = true;
            await userApi.editUser(this.editUserForm.id, {
              name: this.editUserForm.name,
              email: this.editUserForm.email,
              notifications: this.editUserForm.notifications,
              authScopes: authScopes,
              superUsers: this.superUsers
            });
            this.users.data[
              this.editUserForm.index
            ].name = this.editUserForm.name;
            this.users.data[
              this.editUserForm.index
            ].email = this.editUserForm.email;
            this.users.data[this.editUserForm.index].authScopes = authScopes;
            this.users.data[
              this.editUserForm.index
            ].notifications = this.editUserForm.notifications;
            this.$notify({
              title: this.$t("statusMessages.success"),
              message: this.$t("messages.userUpdated"),
              type: "success"
            });
          } catch (error) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.genericError")
            });
          } finally {
            this.editUserDialog.loading = false;
            this.editUserDialog.visible = false;
            this.userToEdit = null;
            this.editUserForm = {
              index: 0,
              id: 0,
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              error: "",
              client: false,
              notifications: false,
              isSuperUser: false
            };

            this.$forceUpdate();
          }
        }
      });
    },

    newUserHandler() {
      this.$refs.newUserForm.validate(async valid => {
        const authScopes = [];
        if (this.newUserForm.client) {
          authScopes.push("client");
          authScopes.push("user");
        }
        if (valid) {
          try {
            this.newUserDialog.loading = true;
            await this.createUser({
              clientId: this.user.data.clientId,
              name: this.newUserForm.name,
              email: this.newUserForm.email,
              password: this.newUserForm.password,
              notifications: this.newUserForm.notifications,
              authScopes: authScopes
            });
            this.$notify({
              title: this.$t("statusMessages.success"),
              message: this.$t("messages.userCreated"),
              type: "success"
            });
          } catch (error) {
            this.$notify.error({
              title: this.$t("statusMessages.error"),
              message: this.$t("messages.genericError")
            });
          } finally {
            this.newUserDialog.loading = false;
            this.newUserDialog.visible = false;
            this.newUserForm = {
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
              error: "",
              client: false,
              notifications: false
            };
          }
        }
      });
    },

    async deleteUserHandler() {
      try {
        this.deleteUserDialog.loading = true;
        await this.deleteUser(this.userToDelete.id);
        this.$notify({
          title: this.$t("statusMessages.success"),
          message: this.$t("messages.userDeleted"),
          type: "success"
        });
      } catch (error) {
        this.$notify.error({
          title: this.$t("statusMessages.error"),
          message: this.$t("messages.genericError")
        });
      } finally {
        this.deleteUserDialog.loading = false;
        this.deleteUserDialog.visible = false;
        this.userToDelete = null;
      }
    }
  }
};
</script>

<style lang="scss">
.user-users-list {
  .user-form {
    margin-top: 15px;

    .edit-user-name {
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .el-input {
      width: 250px;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .edit-user-checkbox {
      display: flex;
      justify-content: left;
      align-items: center;
    }

    .el-form-item {
      display: flex;
      align-items: center;
    }

    .el-form-item label.el-form-item__label {
      // line-height: 40px;
      width: 150px;
      display: flex;
      justify-content: left;
      text-align: left;
      word-break: break-word;
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    .el-table {
      flex: 0 0 821px;

      .cell .column-btn {
        display: flex;
        justify-content: center;
      }
    }
  }

  .el-tag.el-tag--info {
    color: $--color-primary;
  }

  .el-tag.el-tag--info .el-tag__close {
    color: $--color-primary;
  }

  .el-dialog {
    width: 460px;

    @media (max-width: 860px) {
      width: 400px;
    }
  }

  .last-item {
    margin-bottom: -3%;
  }

  &__new.el-button {
    margin: 22px;
  }
}
</style>
