import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import { AuthStoreContext } from "../auth.types";
import authApi from "../api/auth.api";

export default {
  async [actionTypes.AUTH_LOGIN](
    { commit, state }: AuthStoreContext,
    {
      email,
      password,
      clientSecret,
      redirect
    }: {
      email: string;
      password: string;
      clientSecret: string | null;
      redirect: string | null;
    }
  ) {
    try {
      commit(mutationTypes.AUTH_SET_USESR, {
        ...state.user.data,
        loading: true,
        error: null
      });
      if (clientSecret && redirect !== "home" && redirect !== "settings") {
        commit(mutationTypes.AUTH_SET_USESR, {
          data: null,
          loading: false,
          error: "Invalid redirect"
        });
        return;
      } else {
        const tokenRes = await authApi.authToken(email, password, clientSecret);
        window.localStorage.setItem("csrf", tokenRes.data.csrfToken);

        const sessionRes = await authApi.sessionUser();

        if (clientSecret) {
          sessionRes.data.isIframe = true;
          sessionRes.data.redirect = redirect;
        }
        commit(mutationTypes.AUTH_SET_USESR, {
          data: sessionRes.data,
          loading: false,
          error: null
        });
      }
    } catch (error) {
      commit(mutationTypes.AUTH_SET_USESR, {
        data: null,
        loading: false,
        error: error.response
      });
      throw error;
    }
  },

  async [actionTypes.AUTH_SESSION_USER]({ commit, state }: AuthStoreContext) {
    try {
      commit(mutationTypes.AUTH_SET_USESR, {
        ...state.user.data,
        loading: true,
        error: null
      });

      const res = await authApi.sessionUser();

      commit(mutationTypes.AUTH_SET_USESR, {
        data: res.data,
        loading: false,
        error: null
      });

      return res;
    } catch (error) {
      commit(mutationTypes.AUTH_SET_USESR, {
        data: null,
        loading: false,
        error: error.response
      });
      throw error;
    }
  }
};
