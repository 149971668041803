var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-beacons"},[(_vm.resources.loading)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(true),expression:"true"}],staticClass:"user-beacons__loading"}):(_vm.resources.error)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("messages.errorOccurred"))+" ")]):[_c('BatteryIcon',{attrs:{"battery":3,"id":"hidden-battery"}}),_c('div',{staticClass:"location-area"},[_c('h3',[_vm._v(" "+_vm._s(_vm.isClientDefault ? _vm.$tc("labels.beacon", 2).toUpperCase() : _vm.$tc("labels.tag", 2).toUpperCase())+" ")]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("labels.quantity"))+": "+_vm._s(_vm.tableData?.length))]),_c('el-input',{attrs:{"slot":"header","placeholder":_vm.$t('placeholder.search'),"clearable":""},slot:"header",model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('el-table',{staticClass:"full-table",attrs:{"data":_vm.tableData,"border":""},on:{"row-click":data => _vm.$emit('row-click', data)}},[_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("labels.noData"))+" ")]),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.isClientDefault ? 'ID' : _vm.$t('labels.tagId').toUpperCase(),"min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.tagId.toString(16).toUpperCase())+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.label').toUpperCase(),"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label)+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.lastSeen').toUpperCase(),"min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                (_vm.isClientDefault && scope.row.active) ||
                  scope.row.lastPosition != null ||
                  (scope.row.lastPosition == null && !_vm.isClientDefault)
              )?_c('span',{staticClass:"last-update",staticStyle:{"text-align":"left"},style:({ color: _vm.computedColor(scope.row) })},[_vm._v(" "+_vm._s(_vm.moment .tz(scope.row.lastUpdate * 1000, _vm.clientData.timezone) .format("MMM DD YYYY, HH:mm"))+" ")]):_c('span',[_c('hr')])]}}])}),(_vm.isTracker)?_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.status').toUpperCase(),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.status)+" ")]}}],null,false,2964012969)}):_vm._e(),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.range').toUpperCase(),"min-width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(-scope.row.threshold - 16 + " / 16")+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.battery').toUpperCase(),"min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                (_vm.isClientDefault && scope.row.active) ||
                  scope.row.lastPosition != null ||
                  (scope.row.lastPosition == null && !_vm.isClientDefault)
              )?_c('span',[(scope.row.battery === 0)?[_c('img',{attrs:{"src":require("@/assets/battery_dead.svg"),"alt":"no battery"}})]:(
                  scope.row.battery === 1 ||
                    scope.row.battery === 2 ||
                    scope.row.battery === 3
                )?[_c(_vm.batteryIcon,{tag:"component",attrs:{"battery":scope.row.battery}})]:[_c('hr')]],2):_c('span',[_c('hr')])]}}])}),(_vm.isClientDefault)?_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.installed').toUpperCase(),"min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isClientDefault && scope.row.active)?[_c('span',[_vm._v(_vm._s(_vm.$t("confirmation.yes").toUpperCase()))])]:[_c('span',[_vm._v(_vm._s(_vm.$t("confirmation.no").toUpperCase()))])]]}}],null,false,3630676086)}):_vm._e(),(_vm.isClientDefault)?_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('statusMessages.inRoute').toUpperCase(),"width":"95"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.isClientDefault && scope.row.inRoute)?[_c('span',[_vm._v(_vm._s(_vm.$t("confirmation.yes").toUpperCase()))])]:[_c('span',[_vm._v(_vm._s(_vm.$t("confirmation.no").toUpperCase()))])]]}}],null,false,3096122724)}):_vm._e(),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.operations').toUpperCase(),"min-width":"115"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit-buttons"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_c('i',{staticClass:"el-icon-edit"})])],1)]}}])})],2),_c('el-table',{staticClass:"expandable-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('template',{slot:"empty"},[_vm._v(" "+_vm._s(_vm.$t("labels.noData"))+" ")]),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.label)?_c('p',[_c('b',[_vm._v("ID: ")]),_vm._v(_vm._s(scope.row.tagId.toString(16).toUpperCase())+" ")]):_vm._e(),_c('p',[_c('b',[_vm._v("Last seen: ")]),(
                  (_vm.isClientDefault && scope.row.active) ||
                    scope.row.lastPosition != null ||
                    (scope.row.lastPosition == null && !_vm.isClientDefault)
                )?_c('span',{staticClass:"last-update",staticStyle:{"text-align":"left"},style:({ color: _vm.computedColor(scope.row) })},[_vm._v(" "+_vm._s(_vm.moment .tz(scope.row.lastUpdate * 1000, _vm.clientData.timezone) .format("MMM DD YYYY, HH:mm"))+" ")]):_c('span',[_c('hr')])]),(_vm.isTracker)?_c('p',[_c('b',[_vm._v("Status: ")]),_vm._v(_vm._s(scope.row.status))]):_vm._e(),_c('p',[(_vm.isClientDefault && scope.row.inRoute)?[_c('b',[_vm._v(_vm._s(_vm.$t("statusMessages.inRoute"))+": ")]),_vm._v(_vm._s(_vm.$t("confirmation.yes").toUpperCase())+" ")]:[_c('b',[_vm._v(_vm._s(_vm.$t("statusMessages.inRoute"))+": ")]),_vm._v(_vm._s(_vm.$t("confirmation.no").toUpperCase())+" ")]],2),_c('p',[_c('b',[_vm._v(_vm._s(_vm.$t("labels.range"))+":")]),_vm._v(" "+_vm._s(-scope.row.threshold - 16 + " / 16")+" ")]),(_vm.isClientDefault && scope.row.active)?_c('p',[_c('b',[_vm._v("Installed: ")]),_vm._v("YES ")]):_c('p',[_c('b',[_vm._v("Installed: ")]),_vm._v("NO")])]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.isClientDefault
              ? _vm.$tc('labels.beacon', 1).toUpperCase()
              : _vm.$tc('labels.tag', 1).toUpperCase(),"width":"120px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label ? scope.row.label : scope.row.tagId.toString(16).toUpperCase())+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.battery').toUpperCase(),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (
              (_vm.isClientDefault && scope.row.active) ||
                scope.row.lastPosition != null ||
                (scope.row.lastPosition == null && !_vm.isClientDefault)
            )?[(scope.row.battery === 0)?[_c('img',{attrs:{"src":require("@/assets/battery_dead.svg"),"alt":"no battery"}})]:(
                scope.row.battery === 1 ||
                  scope.row.battery === 2 ||
                  scope.row.battery === 3
              )?[_c(_vm.batteryIcon,{tag:"component",attrs:{"battery":scope.row.battery}})]:[_c('hr')]]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"resizable":false,"label":_vm.$t('labels.operations').toUpperCase(),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit-buttons"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_c('i',{staticClass:"el-icon-edit"})])],1)]}}])})],2)],1)],(_vm.resources.data.tags.length)?_c('div',{staticClass:"refresh"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateBeacons()}}},[_vm._v(_vm._s(_vm.$t("button.refreshStatus")))])],1):_vm._e(),_c('EditDialog',{attrs:{"assetType":_vm.isClientDefault ? 'beacon' : 'tag',"assetPrevInfo":_vm.tagToEdit,"isVisible":_vm.editTagDialog.visible,"rules":_vm.rules},on:{"update-edit-dialog-visibility":value => (_vm.editTagDialog.visible = value)}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }