<template>
  <div v-if="isVisible">
    <el-dialog
      class="payment-warning__dialog"
      :title="$t('dialogs.paymentWarning.title')"
      :width="windowWidth < 1024 ? '95%' : '920px'"
      :visible="isVisible"
      @close="$emit('hide-payment-warning')"
    >
      <div class="payment-warning__div">
        {{ $t("dialogs.paymentWarning.message1") }} <b>Oct 10th 2024</b>.

        <br />
        <br />
        <br />
        <br />
        {{ $t("dialogs.paymentWarning.message2") }} <b>Dec 12th 2024</b>
        {{ $t("dialogs.paymentWarning.message3") }}

        <br />
        <br />
        <br />
        <br />
        {{ $t("dialogs.paymentWarning.message4") }}
        <b>jose.valente@azitek.io</b>.
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PaymentWarning",

  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      windowWidth: window.innerWidth
    };
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>

<style lang="scss" scoped>
.payment-warning {
  &__dialog {
    ::v-deep .el-dialog__title {
      font-size: 34px;
      color: red;
    }
  }

  &__div {
    font-size: 34px;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
}
</style>
