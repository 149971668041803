<template>
  <el-dialog
    :title="$t('dialogs.changePassword.title')"
    visible
    class="user-account__change-password-dialog"
    :close-on-click-modal="false"
    @close="dismissDialog()"
  >
    <el-form
      label-position="top"
      :model="passwordForm"
      :rules="rules"
      ref="passwordForm"
      label-width="120px"
      class="user-account__password-form"
      hide-required-asterisk
    >
      <el-form-item
        :label="$t('dialogs.changePassword.currentPassword')"
        prop="currentPassword"
      >
        <el-input
          :type="revealCurrentPassword ? 'text' : 'password'"
          v-model="passwordForm.currentPassword"
          autocomplete="off"
        >
          <el-button
            tabindex="-1"
            @click="revealCurrentPassword = !revealCurrentPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('dialogs.changePassword.newPassword')"
        prop="newPassword"
      >
        <el-input
          :type="revealNewPassword ? 'text' : 'password'"
          v-model="passwordForm.newPassword"
          autocomplete="off"
        >
          <el-button
            tabindex="-1"
            @click="revealNewPassword = !revealNewPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item
        :label="$t('dialogs.changePassword.confirmNewPassword')"
        prop="confirmNewPassword"
      >
        <el-input
          :type="revealConfirmPassword ? 'text' : 'password'"
          v-model="passwordForm.confirmNewPassword"
          autocomplete="off"
        >
          <el-button
            tabindex="-1"
            @click="revealConfirmPassword = !revealConfirmPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
        <div
          v-if="passwordForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ passwordForm.error }}
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dismissDialog()">{{ $t("button.cancel") }}</el-button>
      <el-button type="primary" @click="submitForm('passwordForm')">{{
        $t("button.save")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script lang="js">

import { mapState, mapGetters, mapActions } from 'vuex'
import userApi from "@/modules/user/api/user.api.ts";
import authApi from "@/modules/auth/api/auth.api";
import * as routerTypes from '@/router/router.types.ts'

export default {
  data() {

    const validateNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter your new password'));
      }
      callback()
    };
    const validateNewPasswordConfirmation = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please confirm your new password'));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error('Passwords don\'t match.'));
      } else {
        callback();
      }
    };

    return {
      revealCurrentPassword: false,
      revealNewPassword: false,
      revealConfirmPassword: false,
      passwordDialogVisible: true,
      passwordForm: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        error: ''
      },
      rules: {
        currentPassword: [
          {
            required: true,
            message: this.$t('messages.passwordRequired'),
            trigger: "blur"
          }
        ],
        newPassword: [
          {
            min: 8,
            max: 64,
            message: this.$t('messages.passwordLabelLength'),
            trigger: "blur"
          },
          { validator: validateNewPassword, trigger: 'blur' }
        ],
        confirmNewPassword: [
          {
            min: 8,
            max: 64,
            message: this.$t('messages.passwordLabelLength'),
            trigger: "blur"
          },
          { validator: validateNewPasswordConfirmation, trigger: 'blur' }
        ]
      }
    };
  },

    computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapGetters("auth", ["isChangePasswordUser"]),
  },

  methods: {
    ...mapActions({
      resetStore: "resetStore"
    }),
    onInputChange () {
      if (this.passwordForm.error) {
        this.passwordForm.error = ''
      }
    },
    submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              await userApi.updateUser(this.passwordForm.newPassword, this.user.data.id)
              this.$notify({
                title: 'Success',
                message: 'Password changed successfully',
                type: 'success'
              });
              this.dismissDialog()
              if (this.isChangePasswordUser)
                this.logout()
            } catch (_) {
              this.passwordForm.error = 'Something went wrong. Please try again.'
            }
          }
        });
    },
    dismissDialog () {
      this.$router.push({
        name: routerTypes.ROUTE_USER_SETTINGS,
        params: {
          tab: routerTypes.ROUTE_USER_ACCOUNT
        }
      })
    },
    async logout() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("messages.loggingOut")
      });
      try {
        await authApi.logout();
      } catch (_) {
        // Do nothing
      }
      setTimeout(() => {
        loading.close();
        window.localStorage.removeItem("csrf");
        window.localStorage.removeItem("map-zoom");
        window.localStorage.removeItem("map-center");
        this.resetStore();
        this.$router.push({
          name: routerTypes.ROUTE_SESSION_LOGIN
        });
      }, 1250); // This setTimeout was intentionally placed due to UX purposes and logic constraints
    },
  }
};
</script>

<style lang="scss">
.user-account {
  padding: 24px 0;
  display: flex;
  justify-content: center;

  &__form {
    flex: 0 0 250px;
    padding: 24px;

    @media (min-width: $xs) {
      flex: 0 0 400px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    cursor: text;
    color: $--color-primary;
  }

  .el-form-item__content {
    position: relative;

    span {
      position: absolute;
      right: 2px;
      top: -28px;
      cursor: pointer;
    }
  }

  &__change-password-dialog .el-dialog {
    width: 300px;

    @media (min-width: $md) {
      width: 420px;
    }
  }
}
</style>
