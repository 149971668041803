import { AuthState } from "../auth.types";

export const initialState = () => ({
  user: {
    data: {
      id: -1,
      clientId: -1,
      email: "",
      name: "",
      authScopes: "",
      createdDate: -1,
      lastUpdate: -1,
      clientType: 0,
      isIframe: false,
      redirect: ""
    },
    isLoading: false,
    error: {
      code: 0,
      isError: false,
      message: ""
    }
  }
});

export default initialState() as AuthState;
