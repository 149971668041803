<template>
  <div class="metrics">
    <el-card :shadow="shadow">
      <div slot="header" :style="computeColor">
        <div class="content">
          <div class="left-content">
            <span
              class="constraint-icon"
              v-if="
                usageInfo.constraintTag != null ||
                  usageInfo.constraintShiftStart != 0 ||
                  usageInfo.constraintShiftEnd != 86399
              "
            >
              <i
                :class="
                  usageInfo.constraintTag != null
                    ? 'el-icon-location-outline'
                    : usageInfo.constraintShiftStart != 0 ||
                      usageInfo.constraintShiftEnd != 86399
                    ? 'el-icon-time'
                    : ''
                "
              >
              </i>
            </span>
            <h3 class="route-label">{{ usageInfo.routeLabel }}</h3>
          </div>
          <span style="float: right; font-size: 14px">
            <span
              v-if="
                usageInfo.constraintShiftStart != 0 ||
                  usageInfo.constraintShiftEnd != 86399
              "
            >
              {{ formatTime(usageInfo.constraintShiftStart) }} -
              {{ formatTime(usageInfo.constraintShiftEnd) }}
            </span>
            <span v-else-if="usageInfo.constraintTag">
              {{ usageInfo.constraintTag }}
            </span>
            <el-tooltip
              placement="top"
              :content="$t('analytics.lapAnalytics')"
              :hide-after="2000"
            >
              <el-button @click="redirectToLapAnalytics()">
                <img src="@/assets/bar-chart.svg" :alt="$t('alt.barChart')" />
              </el-button>
            </el-tooltip>
          </span>
        </div>
      </div>
      <div class="time-based-metrics">
        <span class="date">{{ $t("labels.today") }}</span>
        <div class="card-data">
          <div
            :class="{
              'card-data__valid':
                getPercentage(
                  usageInfo.dCount,
                  usageInfo.dCount + usageInfo.dCountError
                ) >= 90
            }"
          >
            <span
              v-if="
                getPercentage(
                  usageInfo.dCount,
                  usageInfo.dCount + usageInfo.dCountError
                ) >= 90
              "
              ><i class="el-icon-top"></i>
            </span>
            <b>{{ usageInfo.dCount || 0 }}</b>
            {{ $t("labels.valid").toLowerCase() }} ({{
              getPercentage(
                usageInfo.dCount,
                usageInfo.dCount + usageInfo.dCountError
              )
            }}%)
          </div>
          <div
            :class="{
              'card-data__error':
                getPercentage(
                  usageInfo.dCountError,
                  usageInfo.dCount + usageInfo.dCountError
                ) >= 10
            }"
          >
            <span
              v-if="
                getPercentage(
                  usageInfo.dCountError,
                  usageInfo.dCount + usageInfo.dCountError
                ) >= 10
              "
              ><i class="el-icon-bottom"></i
            ></span>
            <b>{{ usageInfo.dCountError || 0 }}</b>
            {{ $t("labels.errors").toLowerCase() }} ({{
              getPercentage(
                usageInfo.dCountError,
                usageInfo.dCount + usageInfo.dCountError
              )
            }}%)
          </div>

          <div class="card-data__average-duration">
            <b>
              {{
                usageInfo.dAvg
                  ? moment.utc(usageInfo.dAvg * 1000).format("HH") +
                    "h" +
                    moment.utc(usageInfo.dAvg * 1000).format("mm") +
                    "m"
                  : $t("labels.noInfoOf")
              }}
            </b>
            {{ $t("labels.avgDuration").toLowerCase() }}
          </div>
        </div>
      </div>

      <div class="time-based-metrics" v-if="showLastTwoWeeks">
        <span class="date">{{ $t("labels.lastTwoWeeks") }}</span>

        <div class="card-data">
          <div
            :class="{
              'card-data__valid':
                getPercentage(
                  usageInfo.twCount,
                  usageInfo.twCount + usageInfo.twErrors
                ) >= 90
            }"
          >
            <span
              v-if="
                getPercentage(
                  usageInfo.twCount,
                  usageInfo.twCount + usageInfo.twErrors
                ) >= 90
              "
              ><i class="el-icon-top"></i>
            </span>
            <b>{{ usageInfo.twCount || 0 }}</b>
            {{ $t("labels.valid").toLowerCase() }} ({{
              getPercentage(
                usageInfo.twCount,
                usageInfo.twCount + usageInfo.twErrors
              )
            }}%)
          </div>
          <div
            :class="{
              'card-data__error':
                getPercentage(
                  usageInfo.twErrors,
                  usageInfo.twCount + usageInfo.twErrors
                ) >= 10
            }"
          >
            <span
              v-if="
                getPercentage(
                  usageInfo.twErrors,
                  usageInfo.twCount + usageInfo.twErrors
                ) >= 10
              "
              ><i class="el-icon-bottom"></i
            ></span>
            <b>{{ usageInfo.twErrors || 0 }}</b>
            {{ $t("labels.invalid").toLowerCase() }} ({{
              getPercentage(
                usageInfo.twErrors,
                usageInfo.twCount + usageInfo.twErrors
              )
            }}%)
          </div>

          <div class="card-data__average-duration">
            <b>
              {{
                usageInfo.twAvg
                  ? moment.utc(usageInfo.twAvg * 1000).format("HH") +
                    "h" +
                    moment.utc(usageInfo.twAvg * 1000).format("mm") +
                    "m"
                  : $t("labels.noData")
              }}
            </b>
            {{ $t("labels.avgDuration").toLowerCase() }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import moment from "moment-timezone";
import * as routerTypes from "@/router/router.types";

export default {
  name: "RouteMetricsCard",

  props: {
    usageInfo: {
      type: Object,
      default: () => ({})
    },
    isActiveRoute: {
      type: Boolean,
      default: false
    },
    showLastTwoWeeks: {
      type: Boolean,
      default: true
    },
    shadow: {
      type: String,
      default: "always"
    }
  },
  data() {
    return {
      moment
    };
  },
  computed: {
    computeColor() {
      return `
        background-color: ${this.isActiveRoute ? "#BEEACA" : "#F1F1F1"}; 
        color: ${this.isActiveRoute ? "#3C8300" : "#545454"}; 
        height: 100%;
        width: 100%;
        display: flex;
        `;
    }
  },
  methods: {
    getPercentage(divisor, dividend) {
      if (dividend)
        return Number.parseFloat((divisor / dividend) * 100).toFixed(1);
      else return 0;
    },
    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    },
    redirectToLapAnalytics() {
      this.$router.push({
        name: routerTypes.ROUTE_STATS_LAPS,
        params: { routeId: this.usageInfo.routeId }
      });
    }
  }
};
</script>

<style lang="scss">
.metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
  min-width: 350px;

  @media (max-width: $sm) {
    width: 100%;
    padding: 0px 13px;
  }

  .el-card {
    width: 100%;
    border-radius: 5px;

    &__header {
      display: flex;
      padding: 0;
      border-bottom: none;

      .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 9px 15px;

        .left-content {
          display: flex;

          .constraint-icon {
            font-size: 14px;
            padding-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .route-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 16px;
            font-weight: bold;
            text-align: left;
            word-break: break-word;
          }
        }

        .el-button {
          padding: 3px 5px;
          margin-left: 0;
        }
      }
    }
    &__body {
      display: flex;
      justify-content: space-between;
      padding: 14px 18px 24px;

      @media (max-width: $sm) {
        padding: 15px 19px 15px 20px;
        gap: 3px;
      }

      .time-based-metrics {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .date {
          font-size: 16px;
          font-weight: bold;
          text-align: left;
        }

        .card-data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          font-size: 14px;

          &__valid {
            color: #008000;
            font-weight: bold;
          }
          &__error {
            color: #ff0000;
            font-weight: bold;
          }
          &__average-duration {
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
